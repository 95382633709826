var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import BaseModal from "../base_modal";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Button } from "../components/button";
import { Separator } from "../components/separator";
import { createExternalApiToken, deleteExternalApiToken, getExternalApiTokens, } from "../common/endpoints";
import WaitingSpinner from "../waiting_spinner";
import { confirmModal } from "../common/confirmation_modal";
const ApiKeys = () => {
    const [isCreatingApiKey, setIsCreatingApiKey] = useState(false);
    const { data: apiKeys } = useQuery({
        queryKey: ["api_keys"],
        queryFn: getExternalApiTokens,
    });
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: deleteExternalApiToken,
        onSuccess: (_, tokenJwt) => {
            queryClient.setQueryData(["api_keys"], (prev) => {
                return (prev === null || prev === void 0 ? void 0 : prev.filter((token) => token.jwt !== tokenJwt)) || [];
            });
        },
    });
    const createMutation = useMutation({
        mutationFn: createExternalApiToken,
        onSuccess: (createdToken) => {
            queryClient.setQueryData(["api_keys"], (prev) => [
                ...(prev || []),
                createdToken,
            ]);
        },
    });
    if (!apiKeys) {
        return _jsx(WaitingSpinner, { text: "Loading API keys..." });
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "mt-8 mb-8 m-auto inline-flex flex-col items-start p-6 gap-6 rounded-lg border-solid border-gray-300 bg-white lg:w-[720px] w-[480px]" }, { children: [_jsx("span", Object.assign({ className: "font-black text-lg" }, { children: "API keys" })), _jsx(Separator, {}), !(apiKeys === null || apiKeys === void 0 ? void 0 : apiKeys.length) && (_jsx(Button, Object.assign({ onClick: () => setIsCreatingApiKey(true) }, { children: "Create API key" }))), _jsx("div", Object.assign({ className: "w-full" }, { children: apiKeys === null || apiKeys === void 0 ? void 0 : apiKeys.map((apiKey) => (_jsxs("div", Object.assign({ className: "bg-white shadow-md rounded-lg p-5 mb-5 border border-gray-200" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center mb-2" }, { children: [_jsx("h2", Object.assign({ className: "text-lg font-semibold text-gray-900" }, { children: apiKey.name })), _jsxs("div", Object.assign({ className: "flex space-x-3" }, { children: [_jsx(Button, Object.assign({ onClick: () => navigator.clipboard.writeText(apiKey.jwt) }, { children: "Copy JWT" })), _jsx(Button, Object.assign({ variant: "destructive", onClick: () => confirmModal(() => deleteMutation.mutateAsync(apiKey.jwt), "Delete API key", "Are you sure you want to delete this API key?", "Delete", "Cancel") }, { children: "Delete" }))] }))] })), _jsxs("div", Object.assign({ className: "mb-3 text-sm text-gray-600" }, { children: [_jsx("span", Object.assign({ className: "font-semibold" }, { children: "Scopes: " })), apiKey.scopes.join(", ")] })), _jsxs("div", Object.assign({ className: "text-xs text-gray-500" }, { children: [_jsx("span", Object.assign({ className: "font-semibold" }, { children: "JWT: " })), "****", apiKey.jwt.slice(-6)] }))] }), apiKey.id))) }))] })), isCreatingApiKey && (_jsx(BaseModal, { title: "Create API key", setOpen: () => setIsCreatingApiKey(false), description: "", fields: [
                    {
                        name: "API key name",
                        value: "",
                        type: "text",
                        required: true,
                        placeholder: "API key name",
                    },
                    {
                        name: "Scopes",
                        value: [],
                        type: "multi_select",
                        options: [
                            { label: "call:read:admin", value: "call:read:admin" },
                            {
                                label: "transcript:read:admin",
                                value: "transcript:read:admin",
                            },
                        ],
                        required: true,
                    },
                ], submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    const name = fields[0].value;
                    const scopes = fields[1].value;
                    yield createMutation.mutateAsync({ name, scopes });
                    setIsCreatingApiKey(false);
                }), submitText: "Create API key", open: isCreatingApiKey }))] }));
};
export default ApiKeys;
