import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { PhoneIcon, UsersIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import CallTimeDisplay from "./call_time_display";
import FreeTimeDisplay from "./free_time_display";
import WaitingSpinner from "../waiting_spinner";
import { formatToMM_DD_YYYY, truncateString } from "../strings";
import AutojoinDisplay from "./auto_join_display";
import NoCallsPlaceholder from "./no_calls";
import useIntersectionObserver from "../common/intesection_observer";
import { CallItemLoaderFrame } from "../common/loaders";
import Badge from "../common/badge";
import { Button } from "../components/button";
import AccountPopoverV2 from "../common/account_popover_v2";
import OpportunityPopover from "../common/opportunity_popover";
import { useNavigate } from "react-router-dom";
const calculateFreeTimeBetweenCalls = (call, previousCall) => {
    if (!(previousCall === null || previousCall === void 0 ? void 0 : previousCall.call_end_time))
        return null;
    const minutesFree = (new Date(call.call_time).getTime() -
        new Date(previousCall.call_end_time).getTime()) /
        60000;
    return minutesFree > 0
        ? { minutesFree, startTime: previousCall.call_end_time }
        : null;
};
const CallListItemsWideScreen = React.memo(({ calls, selectedCallId, setSelectedCallId, showLoading, fetchNextPage, tenant, updateSelectedCall, }) => {
    if (calls.length === 0) {
        return _jsx(NoCallsPlaceholder, { showLoading: showLoading });
    }
    const shouldShowJoin = (call) => {
        const callTime = new Date(call.call_time);
        const callEndTime = call.call_end_time
            ? new Date(call.call_end_time)
            : undefined;
        const now = new Date();
        const tenMinutesFromNow = new Date(now.getTime() + 10 * 60000);
        const callIn10MinsFromNow = callTime > now && callTime < tenMinutesFromNow;
        const callStarted = callTime <= now;
        const callHasNotEnded = !!callEndTime && callEndTime > now;
        return callIn10MinsFromNow || (callStarted && callHasNotEnded);
    };
    const callsGroupedByDate = calls.reduce((acc, call) => {
        const date = formatToMM_DD_YYYY(new Date(call.call_time));
        (acc[date] = acc[date] || []).push(call);
        return acc;
    }, {});
    // This determines when the "infinite scroll" should be triggered
    const totalCalls = calls.length;
    let intesectionObeserverCall = null;
    if (totalCalls > 0 && totalCalls < 5) {
        intesectionObeserverCall = calls[totalCalls - 1];
    }
    else {
        intesectionObeserverCall = calls[totalCalls - 4];
    }
    const loadMoreRef = useIntersectionObserver(() => {
        if (fetchNextPage) {
            fetchNextPage();
        }
    }, { threshold: 1.0 });
    const navigate = useNavigate();
    return (_jsxs("div", Object.assign({ className: "w-full gap-1 flex flex-col" }, { children: [Object.entries(callsGroupedByDate).map(([date, callsForDate], index) => {
                const callsAndFreeIntervals = [];
                let previousCall = null;
                for (const call of callsForDate) {
                    const freeTime = calculateFreeTimeBetweenCalls(call, previousCall);
                    if (freeTime) {
                        callsAndFreeIntervals.push(freeTime);
                    }
                    callsAndFreeIntervals.push(call);
                    previousCall = call;
                }
                return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "w-full py-1 rounded-none justify-center items-center self-strech flex text-wds-gray-6 text-[12px] bg-wds-gray-1 font-bold font-lato", style: { lineHeight: "15px" } }, { children: date })), callsAndFreeIntervals.map((callOrFreeTime, index) => {
                            if ("minutesFree" in callOrFreeTime) {
                                return (_jsx(FreeTimeDisplay, { minutesFree: callOrFreeTime.minutesFree }, `${callOrFreeTime.startTime}-${index}`));
                            }
                            const call = callOrFreeTime;
                            const shouldGrayOutPastCall = new Date(call.call_end_time || call.call_time) < new Date();
                            const showJoin = shouldShowJoin(call);
                            return (_jsxs(_Fragment, { children: [!showLoading &&
                                        call.id === (intesectionObeserverCall === null || intesectionObeserverCall === void 0 ? void 0 : intesectionObeserverCall.id) && (_jsx("div", { ref: loadMoreRef, style: { height: "1px" } })), _jsx("div", Object.assign({ className: "px-1" }, { children: _jsx("div", Object.assign({ className: classNames("box-border w-full px-3 flex-col justify-start items-start inline-flex rounded-lg hover:bg-wds-blue-1 cursor-pointer", call.id === selectedCallId ? "bg-wds-blue-1" : ""), onClick: () => setSelectedCallId(call.id) }, { children: _jsxs("div", Object.assign({ className: "self-stretch py-4 flex-col justify-start gap-2 flex" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", Object.assign({ className: classNames("overflow-hidden text-base -tracking-normal truncate", shouldGrayOutPastCall
                                                                    ? "text-wds-gray-5"
                                                                    : "text-wds-black", call.id === selectedCallId
                                                                    ? "font-bold text-wds-black"
                                                                    : "font-normal", call.is_deleted
                                                                    ? "text-wds-gray-4 leading-"
                                                                    : "") }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center" }, { children: [_jsx("span", Object.assign({ className: classNames(shouldGrayOutPastCall
                                                                                ? "line-through"
                                                                                : "") }, { children: truncateString(call.name, call.is_deleted ? 42 : 50) })), call.is_deleted && (_jsx(Badge, { text: "Deleted", classes: "bg-destructive-light ml-2 border border-destructive-border", textClasses: "text-destructive-dark text-xs leading-3" }))] })) })), _jsxs("div", Object.assign({ className: "justify-between items-start inline-flex leading-5" }, { children: [_jsx(CallTimeDisplay, { call: call, className: classNames(shouldGrayOutPastCall
                                                                            ? "text-wds-gray-5"
                                                                            : "text-wds-black", call.id === selectedCallId
                                                                            ? "text-wds-black"
                                                                            : "", call.is_deleted ? "text-wds-gray-4" : "") }), _jsx(AutojoinDisplay, { call: call, selectedCallId: selectedCallId, disabled: shouldGrayOutPastCall })] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center w-full py-1" }, { children: [call.wiser_joined && shouldGrayOutPastCall && (_jsx(Button, Object.assign({ variant: "outline", size: "sm", className: "font-bold mr-2", onClick: (e) => {
                                                                    e.stopPropagation();
                                                                    navigate(`/post_call/${call.id}#post_call`);
                                                                } }, { children: "Review" }))), showJoin && call.supports_meeting_bot && (_jsx(Button, Object.assign({ variant: "default", size: "sm", className: "font-bold mr-2", onClick: (e) => {
                                                                    e.stopPropagation();
                                                                    navigate(`/call/${call.id}`);
                                                                } }, { children: "Join" }))), _jsxs("div", Object.assign({ className: "flex ml-auto" }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: tenant && call.call_type === "External" && (_jsx(AccountPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall })) })), _jsx("div", { children: tenant && call.call_type === "External" && (_jsx(OpportunityPopover, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall })) })] }))] }))] })) }), call.id) }), `callOrFreeTime-${index}`)] }));
                        })] }, date));
            }), showLoading && (_jsx(_Fragment, { children: [...Array(3)].map((_, index) => (_jsx(CallItemLoaderFrame, {}, index))) }))] })));
});
const CallListItemsNarrowScreen = ({ calls, setSelectedCallId, selectedCallId, showLoading, tenant, updateSelectedCall, }) => {
    const navigate = useNavigate();
    if (showLoading) {
        return (_jsx("div", Object.assign({ className: "lg:hidden flex flex-row gap-2 w-full overflow-x-auto p-2" }, { children: _jsx(WaitingSpinner, { text: "Fetching calls" }) })));
    }
    if (!calls.length) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: "lg:hidden flex flex-row gap-2 w-full overflow-x-auto p-2" }, { children: calls.map((call) => {
            const isPastCall = new Date(call.call_time) < new Date();
            return (_jsx("div", Object.assign({ className: classNames("px-4 rounded-lg border-2 flex-col justify-start items-start gap-0.5 inline-flex cursor-pointer hover:bg-blue-100", call.id === selectedCallId
                    ? " bg-primary-foreground border-primary font-bold"
                    : " font-normal", isPastCall ? "text-neutral-500" : ""), onClick: () => setSelectedCallId(call.id) }, { children: _jsxs("div", Object.assign({ className: "self-stretch py-4 flex-col justify-start items-start gap-0.5 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch flex-row justify-between items-start flex" }, { children: [_jsx("div", Object.assign({ className: "h-[42px] w-[206px] self-stretch leading-snug overflow-y-auto" }, { children: truncateString(call.name, 50) })), call.supports_meeting_bot && (_jsx(AutojoinDisplay, { call: call, selectedCallId: selectedCallId, disabled: isPastCall }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center w-full py-1" }, { children: [call.wiser_joined && isPastCall && (_jsx(Button, Object.assign({ variant: "outline", size: "sm", className: "font-bold mr-2", onClick: (e) => {
                                        e.stopPropagation();
                                        navigate(`/post_call/${call.id}#post_call`);
                                    } }, { children: "Review" }))), _jsxs("div", Object.assign({ className: "flex ml-auto" }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: tenant && (_jsx(AccountPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall })) })), _jsx("div", { children: tenant && (_jsx(OpportunityPopover, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall })) })] }))] })), _jsxs("div", Object.assign({ className: "self-stretch mt-5 h-12 flex-col justify-start items-start gap-2 flex" }, { children: [_jsx(CallTimeDisplay, { call: call }), _jsxs("div", Object.assign({ className: "justify-start items-start gap-4 inline-flex text-neutral-500" }, { children: [_jsxs("div", Object.assign({ className: "justify-start items-center gap-1 flex" }, { children: [_jsx(PhoneIcon, { className: "w-3 h-3 relative" }), _jsx("div", Object.assign({ className: "leading-tight" }, { children: call.call_type }))] })), _jsxs("div", Object.assign({ className: "justify-start items-center gap-1 flex" }, { children: [_jsx(UsersIcon, { className: "w-3 h-3 relative" }), _jsx("div", Object.assign({ className: "leading-tight" }, { children: call.participants.length }))] }))] }))] }))] })) }), call.id));
        }) })));
};
export default {
    WideScreen: CallListItemsWideScreen,
    NarrowScreen: CallListItemsNarrowScreen,
};
