import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "../class_names";
import Badge from "../common/badge";
const FieldValueRenderer = (props) => {
    var _a;
    switch (props.fieldSchema.type) {
        case "Boolean":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: _jsx(Badge, { text: props.value === "true" ? "True" : "False", classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.postCallOutputItemId) })));
        case "Single select":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: _jsx(Badge, { text: props.value, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.postCallOutputItemId) })));
        case "Multi select":
            return (_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: (_a = props.value) === null || _a === void 0 ? void 0 : _a.split(";").map((option) => (_jsx(Badge, { text: option, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, option))) })));
        case "Date":
            return (_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: _jsx("span", Object.assign({ className: "font-medium text-sm text-black" }, { children: isNaN(parseInt(props.value))
                        ? null
                        : new Date(parseInt(props.value)).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        }) })) })));
        default:
            return (_jsx("span", Object.assign({ className: classNames("whitespace-pre-wrap", props.expanded ? "line-clamp-none" : "line-clamp-5") }, { children: props.value })));
    }
};
export const CrmEventTargetValueRenderer = (props) => {
    if (!props.value || !props.value.length) {
        return null;
    }
    const valueObject = JSON.parse(props.value);
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [Object.entries(valueObject)
                .slice(/* start= */ 0, /* end= */ props.expanded ? undefined : 3)
                .map(([key, value]) => (_jsxs("div", Object.assign({ className: "flex gap-2 items-start justify center" }, { children: [_jsxs("span", Object.assign({ className: "font-bold" }, { children: [key, ": "] })), _jsx("span", { children: value })] })))), !props.expanded && _jsx("span", { children: "..." })] })));
};
export default FieldValueRenderer;
