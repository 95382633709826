import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as endpoints from "../common/endpoints";
import DataProcessingTab from "./data_processing_tab";
import { markLatestActivityTime } from "../activity_utils";
import CallHeading from "./call_heading";
import VideoWithTranscript from "../video_with_transcript";
import { Tab } from "@headlessui/react";
import classNames from "../class_names";
import { useQuery, useQueryClient } from "react-query";
import { CenterScreen } from "../settings/styled_components";
import { SettingsLoader } from "../common/loaders";
import { useFieldMappings } from "./use_field_mappings";
import { INTEGRATION_DETAILS } from "./data_processing_config";
const PostCall = () => {
    const { callIdParam } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const callId = Number(callIdParam);
    // If you pass post_call as tab query param we will show that tab
    // Re-renders DO not trigger a re-set of the URL params like it was there earlier
    // in the call home page. It's independent and used only once
    // TODO: parse this properly? Is it required
    const [selectedTabIndex, setSelectedTabIndex] = useState(location.hash === "#post_call" ? 1 : 0);
    const [postCallTaskTargetLogosToRender, setPostCallTaskTargetLogosToRender] = useState([]);
    useEffect(() => {
        if (selectedTabIndex === 1 && location.hash !== "#post_call") {
            navigate("#post_call");
        }
    }, [selectedTabIndex]);
    // For jwt token refresh
    markLatestActivityTime();
    const { postCallOutputs, recreateFieldMappings } = useFieldMappings({
        callId,
    });
    useEffect(() => {
        if (!postCallOutputs) {
            return;
        }
        const postCallItemLogos = new Set();
        if (postCallOutputs.some((postCallOutput) => {
            var _a;
            return ((_a = postCallOutput.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) ===
                "FOLLOW_UP_EMAIL";
        })) {
            // Gmail is a special case since these post-call outputs do not have a target specified
            // This should be changed once we have Gmail integration.
            postCallItemLogos.add(INTEGRATION_DETAILS.get("Gmail").icon_url);
        }
        postCallOutputs
            .filter((output) => !!output.target)
            .forEach((output) => {
            if (Array.from(INTEGRATION_DETAILS.values()).find((integration) => integration.name === output.target)) {
                postCallItemLogos.add(Array.from(INTEGRATION_DETAILS.values()).find((integration) => integration.name === output.target).icon_url);
            }
        });
        setPostCallTaskTargetLogosToRender([...postCallItemLogos]);
    }, [postCallOutputs]);
    const queryClient = useQueryClient();
    const { data: tenant, isLoading: tenantLoading, isError: tenantError, } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: endpoints.getActiveTenant,
    });
    const callQuery = useQuery({
        queryKey: ["getCallAndInitialize", callId],
        queryFn: () => endpoints.getLiveCallById(callId),
    });
    if (tenantLoading || callQuery.isLoading) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    else if (tenantError || !tenant || callQuery.isError || !callQuery.data) {
        return _jsx("div", { children: "Error fetching call" });
    }
    const call = callQuery.data;
    const dashboardItems = [
        {
            label: "Call replay",
            renderer: () => _jsx(VideoWithTranscript, { subTab: location.hash }),
        },
        {
            label: (_jsxs("div", Object.assign({ className: "flex gap-1 items-center justify-center self-stretch" }, { children: [_jsx("span", { children: "Tasks" }), _jsx("div", Object.assign({ className: "flex gap-1" }, { children: postCallTaskTargetLogosToRender.slice(0, 3).map((logoUrl) => {
                            return (_jsx("img", { src: logoUrl, className: "shrink-0 w-4 h-4" }, logoUrl));
                        }) }))] }))),
            renderer: () => (_jsx("div", Object.assign({ className: "flex items-center justify-center w-full bg-neutral-100" }, { children: _jsx("div", Object.assign({ className: "w-3/4 h-full my-8" }, { children: _jsx(DataProcessingTab, { call: call }) })) }))),
        },
    ];
    const updateCall = (call) => {
        queryClient.invalidateQueries({
            queryKey: ["getCallAndInitialize", call.id],
        });
        // TODO: Figure out a better way to re-fetch the updated post-call outputs
        recreateFieldMappings();
    };
    return (_jsxs(_Fragment, { children: [_jsx(CallHeading, { call: call, updateCall: updateCall }), _jsx("div", Object.assign({ className: "w-full h-full flex flex-col justify-start items-start rounded-lg bg-wds-gray-2 border-b border-wds-gray-3" }, { children: _jsxs(Tab.Group, Object.assign({ selectedIndex: selectedTabIndex, onChange: setSelectedTabIndex }, { children: [_jsx(Tab.List, Object.assign({ className: "flex px-8 pt-2 mb-4 md:mb-0 items-center self-stretch gap-1 md:gap-4 border-b border-wds-gray-3 bg-white md:rounded-t-lg" }, { children: dashboardItems.map((item, idx) => (_jsx(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-2 md:px-4 py-2 justify-start items-center flex rounded-t-lg border-blue-600 focus:outline-none focus:ring-0", selected
                                    ? "border-b-2 font-bold"
                                    : "hover:bg-blue-100 font-normal") }, { children: item.label }), idx))) })), _jsx(Tab.Panels, Object.assign({ className: "overflow-auto w-full h-full bg-white md:rounded-b-lg" }, { children: dashboardItems.map((item, idx) => (_jsx(Tab.Panel, Object.assign({ className: "w-full h-full" }, { children: item.renderer() }), idx))) }))] })) }))] }));
};
export default PostCall;
