import { useEffect, useState, createContext, useRef } from "react";
import jwt_decode from "jwt-decode";
const axios = require("axios");
import { getCookie } from "./cookies";
import useInterval from "./use_interval";
import WiserFrame from "./wiser_frame";
import WelcomeAuth from "./welcome_auth";
import { BrowserRouter } from "react-router-dom";
import { initLogrocket } from "./logrocket";
import { StreamSocketProvider } from "./common/stream_socket";
import { QueryClient, QueryClientProvider } from "react-query";
import amplitudeInstance, { setTenantInAmplitude } from "./amplitude";

const UserContext = createContext();

const queryClient = new QueryClient();

const App = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPicture, setUserPicture] = useState("");
  const [userIsStaff, setUserIsStaff] = useState(false);
  const [userIsTenantAdmin, setUserIsTenantAdmin] = useState(false);

  function clearUserInfo() {
    localStorage.clear();
    setUserEmail("");
    setUserPicture("");
    setUserIsStaff(false);
    setUserIsTenantAdmin(false);
  }

  function checkIfUserTokenExpired() {
    refreshTokenIfNecessary();

    // Check expiry and clear / jwt / log out as necessary.
    var expiration_time_msec = localStorage.getItem("jwt_expiration_time_msec");
    console.log("Expiration time: " + expiration_time_msec);
    if (expiration_time_msec == null || expiration_time_msec < Date.now()) {
      console.log("User jwt timed out.");
      clearUserInfo();
    }
  }

  const check_refresh_interval_msec = 60 * 60 * 1000;
  function refreshTokenIfNecessary() {
    // We use local storage for last activity time, so that we can easily update what counts
    // as activity from anywhere in the app, without having to pass around a callback.
    const last_activity_time_msec = parseInt(
      localStorage.getItem("last_activity_time_msec"),
    );
    const jwt_issue_time = parseInt(
      localStorage.getItem("jwt_issue_time_msec"),
    );
    if (last_activity_time_msec == null || jwt_issue_time == null) {
      // Make sure we have all the necessary info.
      console.log(
        "refreshTokenIfNecessary: Missing lastActivityTime or jwt_issue_time.",
      );
      return;
    }

    const now = Date.now();
    if (now < jwt_issue_time + check_refresh_interval_msec) {
      // Avoid issuing more often than this interval.
      return;
    }

    if (now > last_activity_time_msec + check_refresh_interval_msec) {
      // No recent-enough activity.
      return;
    }

    console.log("Refreshing jwt.");
    const wiser_jwt = localStorage.getItem("wiser_jwt");
    const csrftoken = getCookie("csrftoken");
    if ((wiser_jwt == null) | (csrftoken == null)) {
      console.log("refreshTokenIfNecessary: Missing jwt or csrftoken.");
      return;
    }

    axios
      .get("/api/refresh_jwt", {
        headers: {
          Authorization: wiser_jwt,
          "X-CSRFToken": csrftoken,
        },
      })
      .catch(function (error) {
        console.log("Error refreshing jwt: " + error);
      })
      .then((response) => {
        let decoded_wiser_jwt = jwt_decode(response.data.wiser_jwt);
        let new_expiration_time_msec =
          decoded_wiser_jwt.expiration_time_sec * 1000;
        localStorage.setItem("wiser_jwt", response.data.wiser_jwt);
        localStorage.setItem(
          "jwt_expiration_time_msec",
          new_expiration_time_msec,
        );
        localStorage.setItem("jwt_issue_time_msec", Date.now());
        console.log(
          "Token successfully refreshed with expiration  " +
            Date(new_expiration_time_msec).toLocaleString(),
        );
      });
  }

  function onUserLoggedOut() {
    // The user logged out by clicking the X in the UI.
    clearUserInfo();
  }

  useInterval(
    () => {
      // We check for expiry every 2 minutes.
      checkIfUserTokenExpired();
    },
    1000 * 60 * 2,
  );

  useEffect(() => {
    // This can happen if the user is coming back to the page after leaving.
    checkIfUserTokenExpired();

    initLogrocket();

    const AMPLITUDE_API_KEY = "fd9bc4c149e6156c09acc09b05200bcd";
    // If we have a jwt, then we can get the user info.  Otherwise, we will
    // render our welcome page with the auth button below.
    var email = localStorage.getItem("user_email");
    if (userEmail != null) {
      setUserEmail(email);
      amplitudeInstance.init(AMPLITUDE_API_KEY, email, {
        defaultTracking: true,
      });
    } else {
      amplitudeInstance.init(AMPLITUDE_API_KEY, { defaultTracking: true });
    }

    var picture = localStorage.getItem("user_picture");
    if (userPicture != null) {
      setUserPicture(picture);
    }

    var isStaff = localStorage.getItem("is_staff_user");
    if (isStaff != null && isStaff === "true") {
      // Items in local storage are stored as strings.
      setUserIsStaff(true);
      setUserIsTenantAdmin(true);
    } else {
      let isTenantAdmin = localStorage.getItem("is_tenant_admin");
      setUserIsTenantAdmin(isTenantAdmin === "true");
    }
  }, []);

  function setUserInfo(
    email,
    pictureUrl,
    issueTimeMsec,
    expirationTimeMsec,
    wiser_jwt,
    home_tenant_id,
    userIsStaff,
    userIsTenantAdmin,
  ) {
    localStorage.setItem("jwt_expiration_time_msec", expirationTimeMsec);
    localStorage.setItem("user_email", email);
    localStorage.setItem("user_picture", pictureUrl);
    localStorage.setItem("wiser_jwt", wiser_jwt);
    localStorage.setItem("home_tenant_id", home_tenant_id);
    localStorage.setItem("is_staff_user", userIsStaff);
    localStorage.setItem("jwt_issue_time_msec", issueTimeMsec);
    localStorage.setItem("is_tenant_admin", userIsTenantAdmin);
    initLogrocket();

    setUserPicture(pictureUrl);
    setUserEmail(email);
    setUserIsStaff(userIsStaff);
    setUserIsTenantAdmin(userIsTenantAdmin);
    console.log("Set amplitude group id", home_tenant_id);
    setTenantInAmplitude(home_tenant_id, userIsStaff, userIsTenantAdmin);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider
        value={{ userEmail, userPicture, userIsStaff, userIsTenantAdmin }}
      >
        <StreamSocketProvider>
          <BrowserRouter>
            <div className="text-neutral-950 font-['Lato'] text-sm font-normal">
              {!userEmail && <WelcomeAuth onSignIn={setUserInfo} />}
              {userEmail && <WiserFrame onUserLoggedOut={onUserLoggedOut} />}
            </div>
          </BrowserRouter>
        </StreamSocketProvider>
      </UserContext.Provider>
    </QueryClientProvider>
  );
};

export { UserContext };
export default App;
