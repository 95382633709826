import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, } from "../components/command";
import classNames from "../class_names";
import { CheckIcon } from "@heroicons/react/24/outline";
/**
 * A more sophisticated faceted filter for the data table
 * Only to be used when "advacedFilters" is enabled
 * A DataTableAdvancedFacetedFilter is used to filter based on a specific column in a table.
 * It lets the user select one or more values, causing only rows with those values to be shown.
 */
export function DataTableAdvancedFacetedFilter({ column, title, options, selectedValues, setSelectedOptions, }) {
    return (_jsxs(Command, Object.assign({ className: "p-1" }, { children: [_jsx("div", Object.assign({ className: "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm [&_[cmdk-input-wrapper]]:border-0 [&_[cmdk-input-wrapper]]:px-0" }, { children: _jsx(CommandInput, { placeholder: title, className: "h-full border-0 pl-0 ring-0", autoFocus: true }) })), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsx(CommandGroup, Object.assign({ className: "px-0" }, { children: options.map((option) => {
                            var _a;
                            const isSelected = selectedValues.has(option.value);
                            return (_jsxs(CommandItem, Object.assign({ onSelect: () => {
                                    if (isSelected) {
                                        selectedValues.delete(option.value);
                                    }
                                    else {
                                        selectedValues.add(option.value);
                                    }
                                    const filterValues = Array.from(selectedValues);
                                    column === null || column === void 0 ? void 0 : column.setFilterValue(filterValues.length ? filterValues : undefined);
                                    setSelectedOptions((prev) => prev.map((item) => item.value === (column === null || column === void 0 ? void 0 : column.id)
                                        ? Object.assign(Object.assign({}, item), { filterValues }) : item));
                                } }, { children: [_jsx("div", Object.assign({ className: classNames("mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary", isSelected
                                            ? "bg-primary text-primary-foreground"
                                            : "opacity-50 [&_svg]:invisible") }, { children: _jsx(CheckIcon, { className: "h-4 w-4", "aria-hidden": "true" }) })), option.icon && (_jsx(option.icon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" })), _jsx("span", { children: option.label }), option.withCount &&
                                        ((_a = column === null || column === void 0 ? void 0 : column.getFacetedUniqueValues()) === null || _a === void 0 ? void 0 : _a.get(option.value)) && (_jsx("span", Object.assign({ className: "ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs" }, { children: column === null || column === void 0 ? void 0 : column.getFacetedUniqueValues().get(option.value) })))] }), option.value));
                        }) })), selectedValues.size > 0 && (_jsxs(_Fragment, { children: [_jsx(CommandSeparator, {}), _jsx(CommandGroup, { children: _jsx(CommandItem, Object.assign({ onSelect: () => {
                                        column === null || column === void 0 ? void 0 : column.setFilterValue(undefined);
                                        setSelectedOptions((prev) => prev.map((item) => item.value === (column === null || column === void 0 ? void 0 : column.id)
                                            ? Object.assign(Object.assign({}, item), { filterValues: [] }) : item));
                                    }, className: "justify-center text-center" }, { children: "Clear filters" })) })] }))] })] })));
}
