var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowUpTrayIcon, ChevronDownIcon, DocumentArrowDownIcon, } from "@heroicons/react/24/outline";
import { markLatestActivityTime } from "../activity_utils";
import Dropdown from "../common/dropdown";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { useState } from "react";
import ImportModal from "./import_modal";
import * as endpoints from "../common/endpoints";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
const CrmHome = () => {
    const [showImportModal, setShowImportModal] = useState(false);
    const location = useLocation();
    const activeTab = location.pathname.split("/").pop();
    const navigate = useNavigate();
    const enableCrmImportViaCsv = useFeatureFlagEnabled("IMPORT_VIA_CSV_CRM");
    // For jwt token refresh
    markLatestActivityTime();
    const crmObject = activeTab === "accounts"
        ? "Account"
        : activeTab === "opportunities"
            ? "Opportunity"
            : "Contact";
    const handleDownloadCsvClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield endpoints.downloadCrmDataCsv(crmObject);
        const nowTime = new Date().getTime();
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${activeTab}${nowTime}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    });
    return (_jsxs("div", Object.assign({ className: "w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100 overflow-x-auto" }, { children: [_jsx("div", Object.assign({ className: "w-full flex flex-col" }, { children: _jsxs("div", Object.assign({ className: "flex justify-between items-center bg-neutral-100" }, { children: [_jsx("div", Object.assign({ className: "ml-4 mt-4" }, { children: _jsx(Dropdown, { options: [
                                    {
                                        value: "accounts",
                                        label: "Accounts",
                                    },
                                    {
                                        value: "opportunities",
                                        label: "Opportunities",
                                    },
                                    {
                                        value: "contacts",
                                        label: "Contacts",
                                    },
                                ], defaultSelected: "accounts", selectedOption: activeTab, onOptionSelect: (option) => {
                                    navigate(`/crm/home/${option}`);
                                }, buttonClasses: "w-60 text-black text-3xl" }) })), _jsxs(Popover, Object.assign({ as: "div", className: "mt-4 mr-4" }, { children: [_jsxs(Popover.Button, Object.assign({ className: "text-blue-500 text-base font-bold px-4 py-2 flex gap-2 items-center hover:bg-blue-100 hover:rounded-full hover:outline-none hover:ring-0 focus:outline-none focus:ring-0" }, { children: ["Actions", _jsx(ChevronDownIcon, { className: "w-5 h-5", "aria-hidden": "true" })] })), _jsxs(Popover.Panel, Object.assign({ className: "absolute top-4 right-0 w-[180px] mt-12 mr-4 bg-white flex flex-col rounded-lg border border-neutral-200 shadow-lg" }, { children: [_jsxs("button", Object.assign({ className: "flex gap-2 p-2 w-full text-left hover:bg-neutral-100", onClick: handleDownloadCsvClick }, { children: [_jsx(DocumentArrowDownIcon, { className: "w-5 h-5 text-blue-500" }), "Download CSV"] })), enableCrmImportViaCsv && (_jsxs("button", Object.assign({ className: "flex gap-2 p-2 w-full text-left hover:bg-neutral-100", onClick: () => setShowImportModal(true) }, { children: [_jsx(ArrowUpTrayIcon, { className: "w-5 h-5 text-blue-500" }), "Upload data"] })))] }))] }))] })) })), _jsx(Outlet, {}), _jsx(ImportModal, { isModalOpen: showImportModal, setIsModalOpen: setShowImportModal })] })));
};
export default CrmHome;
