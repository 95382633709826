import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "../class_names";
import Badge from "./badge";
const MultiSelectDropdown = (props) => {
    const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
    const buttonRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState("bottom");
    const handleOptionClick = (option) => {
        const newSelectedOptions = (selectedOptions.includes(option)
            ? selectedOptions.filter((o) => o !== option)
            : [...selectedOptions, option]).filter((o) => props.options.includes(o));
        setSelectedOptions(newSelectedOptions);
        props.setSelectedOptions(newSelectedOptions);
    };
    useEffect(() => {
        // Check if the button is positioned at the bottom of the viewport
        if (buttonRef.current) {
            const buttonBottomOffset = buttonRef.current.getBoundingClientRect().bottom;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            if (windowHeight - buttonBottomOffset < 200) {
                // If the space below the button is less than 200px, show menu above the button
                setMenuPosition("top");
            }
            else {
                // Otherwise, show menu below the button
                setMenuPosition("bottom");
            }
        }
    }, []);
    return (_jsx(Menu, Object.assign({ as: "div", className: "relative inline-block text-left w-full font-['Lato'] text-neutral-950 text-sm font-normal" }, { children: ({ open }) => {
            var _a, _b;
            return (_jsxs(_Fragment, { children: [_jsxs(Menu.Button, Object.assign({ ref: buttonRef, className: classNames("inline-flex grow justify-between items-center w-full gap-2 px-4 py-2 focus:ring-0 focus:outline-none", open
                            ? (_a = props.buttonClasses) !== null && _a !== void 0 ? _a : "font-bold rounded-full bg-blue-100"
                            : "", (_b = props.buttonClasses) !== null && _b !== void 0 ? _b : "rounded-full bg-white border border-solid border-gray-300") }, { children: [_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: selectedOptions.length === 0
                                    ? "Select an option"
                                    : selectedOptions.map((option) => (_jsx(Badge, { text: option, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, option))) })), _jsx("div", { children: _jsx(ChevronDownIcon, { className: "w-4 h-4", "aria-hidden": "true" }) })] })), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: classNames("mt-1 absolute z-10 right-0 w-full bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", menuPosition === "top" ? "bottom-full mb-1" : "top-full mt-1") }, { children: _jsx("div", Object.assign({ className: "py-2 max-h-52 overflow-y-auto" }, { children: props.options.map((option) => (_jsx(Menu.Item, { children: ({ active }) => (_jsx("button", Object.assign({ onClick: () => handleOptionClick(option), className: classNames("inline-flex flex-row items-center rounded-md w-full px-4 py-2 text-black", active ? "bg-gray-100" : "") }, { children: _jsxs("div", Object.assign({ className: classNames("inline-flex flex-grow flex-row items-center self-stretch justify-between", selectedOptions.includes(option) ? "font-bold" : "") }, { children: [option, selectedOptions.includes(option) && (_jsx(CheckIcon, { className: "w-4 h-4 text-blue-600" }))] })) }))) }, option))) })) })) }))] }));
        } })));
};
export default MultiSelectDropdown;
