import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from "./badge";
import { Button } from "./button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, } from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Separator } from "./separator";
import { CheckIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
/**
 * Applies a faceted filter to the data table. A faceted filter is a searchable checkbox filter.
 * The selected filters are then visible on the filter button itself for easy visibility.
 * This differs from the search select component in that this is not a list of items to select from.
 * But traditional checkboxes coming in through the command component.
 * Question is why not re-use the search select component here?
 * The select drop down would introduce multiple search boxes into the UI which is not a good UX pattern.
 * TL;DR - 1 Search box is more than enough.
 */
export function DataTableFacetedFilter({ column, title, options, }) {
    const selectedValues = new Set(column === null || column === void 0 ? void 0 : column.getFilterValue());
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", className: `h-8 text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6` }, { children: [title, (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.size) > 0 && (_jsxs(_Fragment, { children: [_jsx(Separator, { orientation: "vertical", className: "mx-2 h-4" }), _jsx(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal lg:hidden" }, { children: selectedValues.size })), _jsx("div", Object.assign({ className: "hidden space-x-1 lg:flex" }, { children: selectedValues.size > 2 ? (_jsxs(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal" }, { children: [selectedValues.size, " selected"] }))) : (options
                                        .filter((option) => selectedValues.has(option.value.toString()) ||
                                        selectedValues.has(option.value))
                                        .map((option) => (_jsx(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal" }, { children: option.label }), option.value)))) }))] }))] })) })), _jsx(PopoverContent, Object.assign({ className: "w-[12.5rem] p-0", align: "start" }, { children: _jsxs(Command, { children: [_jsx(CommandInput, { placeholder: title }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsx(CommandGroup, { children: options.map((option) => {
                                        var _a;
                                        const isSelected = selectedValues.has(option.value);
                                        return (_jsxs(CommandItem, Object.assign({ onSelect: () => {
                                                if (isSelected) {
                                                    selectedValues.delete(option.value);
                                                }
                                                else {
                                                    selectedValues.add(option.value);
                                                }
                                                const filterValues = Array.from(selectedValues);
                                                column === null || column === void 0 ? void 0 : column.setFilterValue(filterValues.length ? filterValues : undefined);
                                            } }, { children: [_jsx("div", Object.assign({ className: classNames("mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary", isSelected
                                                        ? "bg-primary text-primary-foreground"
                                                        : "opacity-50 [&_svg]:invisible") }, { children: _jsx(CheckIcon, { className: "h-4 w-4", "aria-hidden": "true" }) })), option.icon && (_jsx(option.icon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" })), _jsx("span", { children: option.label }), option.customCount && (_jsx("span", Object.assign({ className: "ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs" }, { children: option.customCount(option.value) }))), option.withCount &&
                                                    ((_a = column === null || column === void 0 ? void 0 : column.getFacetedUniqueValues()) === null || _a === void 0 ? void 0 : _a.get(option.value)) && (_jsx("span", Object.assign({ className: "ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs" }, { children: column === null || column === void 0 ? void 0 : column.getFacetedUniqueValues().get(option.value) })))] }), option.value));
                                    }) }), selectedValues.size > 0 && (_jsxs(_Fragment, { children: [_jsx(CommandSeparator, {}), _jsx(CommandGroup, { children: _jsx(CommandItem, Object.assign({ onSelect: () => column === null || column === void 0 ? void 0 : column.setFilterValue(undefined), className: "justify-center text-center" }, { children: "Clear filters" })) })] }))] })] }) }))] }));
}
