import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "./button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, } from "./dropdown_menu";
import classNames from "../class_names";
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon, EyeSlashIcon, } from "@heroicons/react/24/outline";
/**
 * Generic component to add sorting and visibility functionality to each column in a table.
 * Meant to be used with `DataTable` component but can be used independently
 * TODO: Implement sorting functionality through backend.
 * @example
 * ```
 * {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  ```
 * Inside your "columns" array use this to add this feature to the header.
 */
export function DataTableColumnHeader({ column, title, className, }) {
    if (!column.getCanSort()) {
        return _jsx("div", Object.assign({ className: classNames(className) }, { children: title }));
    }
    return (_jsx("div", Object.assign({ className: classNames("flex items-center space-x-2", className) }, { children: _jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: "-ml-3 h-8 data-[state=open]:bg-accent text-wds-gray-5 font-bold" }, { children: [_jsx("span", { children: title }), column.getIsSorted() === "desc" ? (_jsx(ArrowDownIcon, { className: "ml-2 h-4 w-4" })) : column.getIsSorted() === "asc" ? (_jsx(ArrowUpIcon, { className: "ml-2 h-4 w-4" })) : (_jsx(ArrowsUpDownIcon, { className: "ml-2 h-4 w-4" }))] })) })), _jsxs(DropdownMenuContent, Object.assign({ align: "start" }, { children: [_jsxs(DropdownMenuItem, Object.assign({ onClick: () => column.toggleSorting(false) }, { children: [_jsx(ArrowUpIcon, { className: "mr-2 h-3.5 w-3.5 text-muted-foreground-dark" }), "Asc"] })), _jsxs(DropdownMenuItem, Object.assign({ onClick: () => column.toggleSorting(true) }, { children: [_jsx(ArrowDownIcon, { className: "mr-2 h-3.5 w-3.5 text-muted-foreground-dark" }), "Desc"] })), _jsx(DropdownMenuSeparator, {}), _jsxs(DropdownMenuItem, Object.assign({ onClick: () => column.toggleVisibility(false) }, { children: [_jsx(EyeSlashIcon, { className: "mr-2 h-3.5 w-3.5 text-muted-foreground-dark" }), "Hide"] }))] }))] }) })));
}
