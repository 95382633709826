var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { cva } from "class-variance-authority";
import classNames from "../class_names";
const kbdVariants = cva("select-none rounded border px-1.5 py-px font-mono text-[0.7rem] font-normal shadow-sm disabled:opacity-50", {
    variants: {
        variant: {
            default: "bg-accent text-accent-foreground",
            outline: "bg-background text-wds-gray-6",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
/**
 * @see
 * https://www.w3schools.com/tags/tag_kbd.asp
 * for more information on the kbd element. It's a native HTML element.
 * A prestyled kbd component that can be styled with classNames instead of specific style classes.
 * This is used in the table component.
 */
const Kbd = React.forwardRef((_a, ref) => {
    var { abbrTitle, children, className, variant } = _a, props = __rest(_a, ["abbrTitle", "children", "className", "variant"]);
    return (_jsx("kbd", Object.assign({ className: classNames(kbdVariants({ variant, className })), ref: ref }, props, { children: abbrTitle ? (_jsx("abbr", Object.assign({ title: abbrTitle, className: "no-underline" }, { children: children }))) : (children) })));
});
Kbd.displayName = "Kbd";
export { Kbd };
