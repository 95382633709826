var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import { debounce } from "../common/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
export function useDataTable(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var { pageCount = -1, filterFields = [], enableAdvancedFilter = false } = _a, props = __rest(_a, ["pageCount", "filterFields", "enableAdvancedFilter"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // Search params
    const search = {
        page: Number(searchParams.get("page")) || 1,
        per_page: Number(searchParams.get("per_page")) || 20,
        sort: searchParams.get("sort"),
    };
    const page = search.page;
    const perPage = (_e = (_b = search.per_page) !== null && _b !== void 0 ? _b : (_d = (_c = props.initialState) === null || _c === void 0 ? void 0 : _c.pagination) === null || _d === void 0 ? void 0 : _d.pageSize) !== null && _e !== void 0 ? _e : 20;
    const sort = (_f = search.sort) !== null && _f !== void 0 ? _f : `${(_j = (_h = (_g = props.initialState) === null || _g === void 0 ? void 0 : _g.sorting) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.id}.${((_m = (_l = (_k = props.initialState) === null || _k === void 0 ? void 0 : _k.sorting) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.desc) ? "desc" : "asc"}`;
    const [column, order] = (_o = sort === null || sort === void 0 ? void 0 : sort.split(".")) !== null && _o !== void 0 ? _o : [];
    // Memoize computation of searchableColumns and filterableColumns
    const { searchableColumns, filterableColumns } = React.useMemo(() => {
        return {
            searchableColumns: filterFields.filter((field) => !field.options),
            filterableColumns: filterFields.filter((field) => field.options),
        };
    }, [filterFields]);
    // Create query string
    const createQueryString = React.useCallback((params) => {
        const newSearchParams = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
        for (const [key, value] of Object.entries(params)) {
            if (value === null) {
                newSearchParams.delete(key);
            }
            else {
                newSearchParams.set(key, String(value));
            }
        }
        return newSearchParams.toString();
    }, [searchParams]);
    // Initial column filters
    const initialColumnFilters = React.useMemo(() => {
        return Array.from(searchParams.entries()).reduce((filters, [key, value]) => {
            const filterableColumn = filterableColumns.find((column) => column.value === key);
            const searchableColumn = searchableColumns.find((column) => column.value === key);
            if (filterableColumn) {
                filters.push({
                    id: key,
                    value: value.split("."),
                });
            }
            else if (searchableColumn) {
                filters.push({
                    id: key,
                    value: [value],
                });
            }
            return filters;
        }, []);
    }, [filterableColumns, searchableColumns, searchParams]);
    // Table states
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState((_q = (_p = props.initialState) === null || _p === void 0 ? void 0 : _p.columnVisibility) !== null && _q !== void 0 ? _q : {});
    const [columnFilters, setColumnFilters] = React.useState(initialColumnFilters);
    // Handle server-side pagination
    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: page - 1,
        pageSize: perPage,
    });
    const pagination = React.useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize]);
    // Handle server-side sorting
    const [sorting, setSorting] = React.useState([
        {
            id: column !== null && column !== void 0 ? column : "",
            desc: order === "desc",
        },
    ]);
    React.useEffect(() => {
        var _a, _b, _c;
        navigate(`?${createQueryString({
            page: pageIndex + 1,
            per_page: pageSize,
            sort: ((_a = sorting[0]) === null || _a === void 0 ? void 0 : _a.id)
                ? `${(_b = sorting[0]) === null || _b === void 0 ? void 0 : _b.id}.${((_c = sorting[0]) === null || _c === void 0 ? void 0 : _c.desc) ? "desc" : "asc"}`
                : null,
        })}`);
    }, [pageIndex, pageSize, sorting]);
    // Handle server-side filtering
    const debouncedSearchableColumnFilters = JSON.parse(debounce(JSON.stringify(columnFilters.filter((filter) => {
        return searchableColumns.find((column) => column.value === filter.id);
    })), 500));
    const filterableColumnFilters = columnFilters.filter((filter) => {
        return filterableColumns.find((column) => column.value === filter.id);
    });
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        // Opt out when advanced filter is enabled, because it contains additional params
        if (enableAdvancedFilter)
            return;
        // Prevent resetting the page on initial render
        if (!mounted) {
            setMounted(true);
            return;
        }
        // Initialize new params
        const newParamsObject = {
            page: 1,
        };
        // Handle debounced searchable column filters
        for (const column of debouncedSearchableColumnFilters) {
            if (typeof column.value === "string") {
                Object.assign(newParamsObject, {
                    [column.id]: typeof column.value === "string" ? column.value : null,
                });
            }
        }
        // Handle filterable column filters
        for (const column of filterableColumnFilters) {
            if (typeof column.value === "object" && Array.isArray(column.value)) {
                Object.assign(newParamsObject, { [column.id]: column.value.join(".") });
            }
        }
        // Remove deleted values
        for (const key of searchParams.keys()) {
            if ((searchableColumns.find((column) => column.value === key) &&
                !debouncedSearchableColumnFilters.find((column) => column.id === key)) ||
                (filterableColumns.find((column) => column.value === key) &&
                    !filterableColumnFilters.find((column) => column.id === key))) {
                Object.assign(newParamsObject, { [key]: null });
            }
        }
        // After cumulating all the changes, push new params
        navigate(`?${createQueryString(newParamsObject)}`);
        table.setPageIndex(0);
    }, [
        JSON.stringify(debouncedSearchableColumnFilters),
        JSON.stringify(filterableColumnFilters),
    ]);
    const table = useReactTable(Object.assign(Object.assign({}, props), { pageCount, state: {
            pagination,
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
        }, enableRowSelection: true, onRowSelectionChange: setRowSelection, onPaginationChange: setPagination, onSortingChange: setSorting, onColumnFiltersChange: setColumnFilters, onColumnVisibilityChange: setColumnVisibility, getCoreRowModel: getCoreRowModel(), getFilteredRowModel: getFilteredRowModel(), getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(), getFacetedRowModel: getFacetedRowModel(), getFacetedUniqueValues: getFacetedUniqueValues(), manualPagination: true, manualSorting: true, manualFiltering: true }));
    return { table };
}
