import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "../components/skeleton";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { DateRangePicker } from "../components/date_picker";
import { CallsTable } from "./calls_table";
import { CallsTableProvider } from "./calls_table_provider";
import { convertSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getCallsForTable, getPlaybookItems } from "../common/endpoints";
import { useEffect, useState } from "react";
export default function IndexPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const newFilterQuery = convertSearchParamsIntoFilterQuery(searchParams, location);
    const page = parseInt(searchParams.get("page") || "1") || 1;
    const perPage = parseInt(searchParams.get("per_page") || "20") || 20;
    const [resetFlag, setResetFlag] = useState(false);
    const [hasOtherFilters, setHasOtherFilters] = useState(false);
    const { data, isLoading, isError } = useQuery(["callsTable", newFilterQuery, page, perPage], () => getCallsForTable(newFilterQuery, page, perPage), {
        refetchOnWindowFocus: false,
        keepPreviousData: true, // Does not re-render the page untill data is loaded
    });
    const { data: playbookItems } = useQuery("playbookItems", getPlaybookItems);
    const calls = (data === null || data === void 0 ? void 0 : data.data) || [];
    const total = (data === null || data === void 0 ? void 0 : data.total) || 0;
    const wiser_joined_count = (data === null || data === void 0 ? void 0 : data.wiser_joined_count) || 0;
    useEffect(() => {
        if (searchParams.get("accounts") ||
            searchParams.get("contacts") ||
            searchParams.get("users") ||
            searchParams.get("from") ||
            searchParams.get("to")) {
            setHasOtherFilters(true);
        }
        else {
            setHasOtherFilters(false);
        }
    }, [searchParams]);
    return (_jsx("div", Object.assign({ className: "gap-2 p-4" }, { children: _jsxs(CallsTableProvider, { children: [!data || isLoading ? (_jsx(Skeleton, { className: "h-7 w-52" })) : (_jsx(DateRangePicker, { triggerSize: "sm", triggerClassName: "ml-auto w-56 sm:w-60", align: "end", resetFlag: resetFlag, setResetFlag: setResetFlag })), !data || isLoading || !playbookItems ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, playbookItems: playbookItems, resetFlag: resetFlag, setResetFlag: setResetFlag, hasOtherFilters: hasOtherFilters, setHasOtherFilters: setHasOtherFilters }))] }) })));
}
