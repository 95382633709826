import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTableAdvancedToolbar } from "../advanced_table_filters/data_table_toolbar";
import { DataTable } from "../components/data_table";
import { DataTableToolbar } from "../components/data_table_toolbar";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { useCallsTable } from "./calls_table_provider";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
import { CallsAsyncFilters } from "./calls_table_async_filters";
import { useSearchParams } from "react-router-dom";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
export function CallsTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const calls = data.calls;
    const total = data.total;
    const wiser_joined_count = data.wiser_joined_count;
    const [searchParams, setSearchParams] = useSearchParams();
    const pageSize = parseInt(searchParams.get("per_page") || "20");
    const { featureFlags } = useCallsTable();
    const pageCount = Math.ceil(total / pageSize);
    const [asyncResetFlag, setAsyncResetFlag] = React.useState(false);
    const accountResearchPageEnabled = useFeatureFlagEnabled("ACCOUNT_RESEARCH_PAGE");
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.playbookItems, accountResearchPageEnabled), [data.playbookItems, accountResearchPageEnabled]);
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = data.playbookItems.map((item) => item.name);
    /**
     * This component can render either a faceted filter or a search filter based on the `options` prop.
     *
     * @prop options - An array of objects, each representing a filter option. If provided, a faceted filter is rendered. If not, a search filter is rendered.
     *
     * Each `option` object has the following properties:
     * @prop {string} label - The label for the filter option.
     * @prop {string} value - The value for the filter option.
     * @prop {React.ReactNode} [icon] - An optional icon to display next to the label.
     * @prop {boolean} [withCount] - An optional boolean to display the count of the filter option.
     */
    const filterFields = [
        {
            label: "Name",
            value: "name",
            placeholder: "Search names",
        },
        {
            label: "Call type",
            value: "call_type",
            options: ["Internal", "External"].map((type) => ({
                label: type,
                value: type,
                withCount: false,
            })),
        },
        {
            label: "Wiser joined",
            value: "wiser_joined",
            // @ts-ignore
            options: ["true", "false"].map((join) => ({
                label: join,
                value: join,
                customCount: (value) => {
                    if (value === "true") {
                        return wiser_joined_count;
                    }
                    else {
                        return total - wiser_joined_count;
                    }
                },
            })),
        },
    ];
    const advancedFilterFields = [
        ...filterFields,
        {
            label: "Trackers",
            value: "playbook_content",
            placeholder: "Search trackers",
        },
        {
            label: "Transcripts",
            value: "transcripts",
            placeholder: "Search transcripts",
        },
    ];
    const { table } = useDataTable({
        data: calls,
        columns,
        pageCount,
        /* optional props */
        filterFields: advancedFilterFields,
        filterFns: {
            wiserCrmTableFilterFn: (row, column, value) => {
                return true;
            },
        },
        enableAdvancedFilter: featureFlags.includes("advancedFilter"),
        initialState: {
            sorting: [{ id: "call_time", desc: true }],
            columnPinning: { right: ["actions"] },
            columnVisibility: Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false }, Object.fromEntries(playbook_item_names.map((name) => [name, false]))),
        },
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    return (_jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { table: table }) }, { children: featureFlags.includes("advancedFilter") ? (_jsx(DataTableAdvancedToolbar, Object.assign({ table: table, filterFields: advancedFilterFields, asyncFilter: _jsx(CallsAsyncFilters, { asyncResetFlag: asyncResetFlag, setAsyncResetFlag: setAsyncResetFlag }), columnIdMap: columnIdMap }, { children: _jsx(CallsTableToolbarActions, { table: table }) }))) : (_jsx(DataTableToolbar, Object.assign({ table: table, filterFields: filterFields, asyncFilter: _jsx(CallsAsyncFilters, { asyncResetFlag: asyncResetFlag, setAsyncResetFlag: setAsyncResetFlag }), hasOtherFilters: data.hasOtherFilters, setResetFlag: data.setResetFlag, setAsyncFilterFlag: setAsyncResetFlag, columnIdMap: columnIdMap }, { children: _jsx(CallsTableToolbarActions, { table: table }) }))) })));
}
