import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { AccountBlockCard } from "./styled_components";
import { getConfigurationForAccountDetailsResearchBlock } from "../../common/endpoints";
import { CenterScreen } from "../../settings/styled_components";
import { SettingsLoader } from "../../common/loaders";
import { BuildingOfficeIcon, CurrencyDollarIcon, DocumentTextIcon, EyeIcon, GlobeAltIcon, MapPinIcon, UserGroupIcon, } from "@heroicons/react/24/outline";
import Badge from "../../common/badge";
// TODO: Figure out a better way to do this!
const ICON_NAME_TO_HEROICON_MAP = {
    GlobeAltIcon: GlobeAltIcon,
    MapPinIcon: MapPinIcon,
    CurrencyDollarIcon: CurrencyDollarIcon,
    EyeIcon: EyeIcon,
    BuildingOfficeIcon: BuildingOfficeIcon,
    UserGroupIcon: UserGroupIcon,
    DocumentTextIcon: DocumentTextIcon,
};
const AccountData = ({ id, icon, fieldLabel, fieldValue, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-1" }, { children: [icon, _jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: fieldLabel }))] })), fieldValue] }), id));
};
export const AccountDetailsBlock = ({ account }) => {
    const { data: crmDataToRenderConfig, isLoading: configLoading, isError: configError, } = useQuery({
        queryKey: ["crmDataToRenderConfig"],
        queryFn: getConfigurationForAccountDetailsResearchBlock,
    });
    const renderAccountCrmData = (crmFieldId) => {
        if (!crmDataToRenderConfig)
            return null;
        const iconName = crmDataToRenderConfig[crmFieldId].icon;
        const FieldLabelIcon = iconName in ICON_NAME_TO_HEROICON_MAP
            ? ICON_NAME_TO_HEROICON_MAP[iconName]
            : DocumentTextIcon;
        const accountCrmData = account.crm_data.find((crmData) => crmData.crm_field.id === crmFieldId);
        if (!accountCrmData) {
            return null;
        }
        const fieldValue = accountCrmData.value;
        // Number, SingleSelect etc. are all rendered in a badge if set,
        // Else zero state is always rendered as text.
        const shouldRenderValueInBadge = accountCrmData.crm_field.content_type_definition.type !== "text" &&
            !!fieldValue &&
            fieldValue.length > 0;
        return (_jsx(AccountData, { id: accountCrmData.crm_field.id, icon: _jsx(FieldLabelIcon, { className: "w-4 h-4 shrink-0 text-wds-gray-5" }), fieldLabel: accountCrmData.crm_field.name, fieldValue: _jsx(_Fragment, { children: shouldRenderValueInBadge ? (_jsx(Badge, { text: fieldValue, classes: "py-0.5 px-2 items-center gap-2 border rounded-lg border-wds-gray-3 bg-wds-gray-1", textClasses: "text-wds-gray-6 font-medium p-0 text-sm" })) : (_jsx(_Fragment, { children: fieldValue && fieldValue.length > 0 ? (_jsx("span", Object.assign({ className: "text-black text-sm" }, { children: fieldValue }))) : (_jsx("span", Object.assign({ className: "text-wds-gray-4 text-sm" }, { children: "Not set for the account" }))) })) }) }, accountCrmData.crm_field.id));
    };
    if (configError) {
        return null;
    }
    return (_jsxs(AccountBlockCard, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/document-search.png", className: "h-6 w-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: "Account details" }))] })), configLoading || !crmDataToRenderConfig ? (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) })) : (_jsxs("div", Object.assign({ className: "flex items-start justify-start gap-x-10 gap-y-6 self-stretch flex-wrap" }, { children: [_jsx(AccountData, { id: "website", icon: _jsx(GlobeAltIcon, { className: "w-4 h-4 text-wds-gray-5" }), fieldLabel: "Website", fieldValue: _jsx("a", Object.assign({ href: account.website, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("span", Object.assign({ className: "text-wds-blue-3 text-sm" }, { children: account.website })) })) }), Object.keys(crmDataToRenderConfig).map((fieldId) => renderAccountCrmData(parseInt(fieldId)))] })))] }));
};
