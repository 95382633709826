import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BriefcaseIcon, ExclamationCircleIcon, GlobeAltIcon, PencilIcon, } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import CallCrmDetailsPopover, { NoCrmObjectMappedPopoverPanel, } from "./call_crm_details_popover";
import { useMemo, useState } from "react";
import CallCrmAccountModal from "./call_crm_account_modal";
import { getAccountLinkedInUrl, getAccountName, getAccountNameForCall, } from "./call_account_utils";
import { Link } from "react-router-dom";
import { Button } from "../components/button";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { getConnectedCrmLogo } from "./crm_logo";
import { getExternalCrmObjectLink } from "../crm/common/external_crm_link";
import { useAppIntegrationData } from "./use_app_integration_data";
const AccountDetails = ({ tenant, call, setCallCrmAccountModalOpen, }) => {
    var _a;
    const accountResearchPageEnabled = useFeatureFlagEnabled("ACCOUNT_RESEARCH_PAGE");
    const accountExistsInCrm = !tenant.connected_crm || !!((_a = call.account) === null || _a === void 0 ? void 0 : _a.external_id);
    const { appIntegrationData } = useAppIntegrationData(tenant.id);
    const externalCrmObjectLink = useMemo(() => {
        var _a;
        if (!tenant.connected_crm || !((_a = call.account) === null || _a === void 0 ? void 0 : _a.external_id)) {
            return undefined;
        }
        return getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], "Account", call.account.external_id);
    }, [appIntegrationData, call, tenant]);
    const renderEditButton = () => {
        return (_jsx("button", Object.assign({ onClick: () => setCallCrmAccountModalOpen(true), className: "flex w-8 h-8 justify-center items-center rounded-full" }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })));
    };
    return (_jsx("div", Object.assign({ className: "flex flex-col items-start gap-2 max-w-sm" }, { children: call.account ? (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-start self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-4" }, { children: [call.account.logo_url ? (_jsx("img", { className: "w-9 h-9", src: call.account.logo_url })) : (_jsx(BriefcaseIcon, { className: "h-9 w-9 text-wds-gray-6" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-[2px]" }, { children: [_jsx("span", Object.assign({ className: "text-base font-bold text-black" }, { children: getAccountName(call.account, "No account name") })), !accountExistsInCrm && (_jsxs("div", Object.assign({ className: "flex items-center justify-start gap-1 text-yellow-600" }, { children: [_jsx(ExclamationCircleIcon, { className: "w-4 h-4" }), _jsx("span", { children: "No account associated" }), _jsx("button", Object.assign({ onClick: () => setCallCrmAccountModalOpen(true), className: "text-wds-blue-3" }, { children: "Resolve" }))] }))), _jsx("span", Object.assign({ className: "text-sm text-wds-gray-6 font-normal" }, { children: call.account.industry && call.account.industry.length > 0
                                                ? call.account.industry
                                                : "No industry found" }))] }))] })), renderEditButton()] })), _jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center" }, { children: _jsx("a", Object.assign({ href: call.account.website, target: "_blank", rel: "noreferrer" }, { children: _jsx(GlobeAltIcon, { className: "h-5 w-5 text-wds-blue-4" }) })) })), _jsx("a", Object.assign({ href: call.account.website, target: "_blank", className: "w-full" }, { children: _jsx("p", Object.assign({ className: "text-left break-all text-wds-blue-3 truncate text-sm font-medium" }, { children: call.account.website })) }))] })), _jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: call.account.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-4 w-4", src: call.account.linkedin_profile_url &&
                                        call.account.linkedin_profile_url.length > 0
                                        ? "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png"
                                        : "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-disable.png" }) })) })), _jsx("a", Object.assign({ href: call.account.linkedin_profile_url, target: "_blank", className: "w-full" }, { children: _jsx("p", Object.assign({ className: "text-left break-all text-wds-blue-3 truncate text-sm font-medium", style: {
                                    // In order to render the end of the URL rather than common start
                                    direction: "rtl",
                                } }, { children: getAccountLinkedInUrl(call.account, "No linkedin profile found") })) }))] })), externalCrmObjectLink && (_jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("img", { className: "h-4 w-4", src: getConnectedCrmLogo(tenant.connected_crm) }) })), _jsx(Link, Object.assign({ to: externalCrmObjectLink, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "text-wds-blue-3 h-auto p-0 font-medium" }, { children: `Open in ${tenant.connected_crm}` })) }))] }))), accountResearchPageEnabled && (_jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("img", { className: "h-4 w-4", src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png" }) })), _jsx(Link, Object.assign({ to: `/account/${call.account.id}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "text-wds-blue-3 h-auto p-0 font-medium" }, { children: "See more details in Wiser" })) }))] })))] })) : (_jsx(NoCrmObjectMappedPopoverPanel, { title: "No account mapped", editButtonClickHandler: () => setCallCrmAccountModalOpen(true) })) })));
};
const AccountPopoverV2 = (props) => {
    // Boolean to find if the account exists in user's CRM.
    // If the user is connected to an external CRM,
    // we check if the external_id of the account is populated.
    // If the user is not connected to external CRM, presence of an account is
    // necessary and sufficient condition.
    const accountExistsInCrm = !!props.call.account &&
        (!props.tenant.connected_crm || !!props.call.account.external_id);
    const [callCrmAccountModalOpen, setCallCrmAccountModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(CallCrmDetailsPopover, { button: _jsxs("div", Object.assign({ className: classNames("flex py-[2px] px-2 gap-2 items-center rounded-lg border", accountExistsInCrm
                        ? "border-wds-gray-3 bg-wds-gray-1 text-wds-gray-6"
                        : "border-yellow-300 bg-yellow-100 text-yellow-800") }, { children: [_jsx(BriefcaseIcon, { className: classNames("w-4 h-4", accountExistsInCrm ? "text-wds-gray-6" : "text-yellow-600") }), _jsxs("div", Object.assign({ className: "font-normal leading-snug" }, { children: [!accountExistsInCrm && props.call.account && (_jsx("span", { children: "Potential account: " })), getAccountNameForCall(props.call, "No account")] }))] })), panel: _jsx(AccountDetails, { tenant: props.tenant, call: props.call, setCallCrmAccountModalOpen: setCallCrmAccountModalOpen }) }), _jsx(CallCrmAccountModal, { tenant: props.tenant, call: props.call, open: callCrmAccountModalOpen, setOpen: setCallCrmAccountModalOpen, updateSelectedCall: props.updateSelectedCall })] }));
};
export default AccountPopoverV2;
