import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { dataTableConfig } from "../table_helpers";
import { Button } from "../components/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../components/dropdown_menu";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, } from "../components/select";
import { Separator } from "../components/separator";
import { DataTableFacetedFilter } from "../components/data_table_faceted_filter";
import { debounce } from "../common/debounce";
import { Bars3Icon, ClipboardIcon, EllipsisHorizontalIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";
export function DataTableMultiFilter({ table, allOptions, options, setSelectedOptions, defaultOpen, }) {
    const [open, setOpen] = React.useState(defaultOpen);
    const [operator, setOperator] = React.useState(dataTableConfig.logicalOperators[0]);
    return (_jsxs(Popover, Object.assign({ open: open, onOpenChange: setOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", className: "h-7 truncate rounded-full text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: [_jsx(Bars3Icon, { className: "mr-2 h-3 w-3", "aria-hidden": "true" }), options.length, " rule"] })) })), _jsxs(PopoverContent, Object.assign({ className: "w-fit p-0 text-xs", align: "start" }, { children: [_jsx("div", Object.assign({ className: "space-y-2 p-4" }, { children: options.map((option, i) => {
                            var _a;
                            return (_jsx(MultiFilterRow, { i: i, option: option, table: table, allOptions: allOptions, options: options, setSelectedOptions: setSelectedOptions, operator: operator, 
                                // @ts-ignore
                                setOperator: setOperator }, (_a = option.id) !== null && _a !== void 0 ? _a : i));
                        }) })), _jsx(Separator, {}), _jsx("div", Object.assign({ className: "p-1" }, { children: _jsx(Button, Object.assign({ "aria-label": "Delete filter", variant: "ghost", size: "sm", className: "w-full justify-start text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6", onClick: () => {
                                setSelectedOptions((prev) => prev.filter((item) => !item.isMulti));
                            } }, { children: "Delete filter" })) }))] }))] })));
}
/**
 * A advanced multi filter component which can apply multiple filters on a data table
 */
export function MultiFilterRow({ i, table, option, allOptions, options, setSelectedOptions, operator, setOperator, }) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = React.useState("");
    const debounceValue = debounce(value, 300);
    const [selectedOption, setSelectedOption] = React.useState(options[0]);
    const filterVarieties = (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.options.length)
        ? ["is", "is not"]
        : ["contains", "does not contain", "is", "is not"];
    const [filterVariety, setFilterVariety] = React.useState(filterVarieties[0]);
    // Update filter variety
    React.useEffect(() => {
        if (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.options.length) {
            setFilterVariety("is");
        }
    }, [selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.options.length]);
    // Create query string
    const createQueryString = React.useCallback((params) => {
        const newSearchParams = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
        for (const [key, value] of Object.entries(params)) {
            if (value === null) {
                newSearchParams.delete(key);
            }
            else {
                newSearchParams.set(key, String(value));
            }
        }
        return newSearchParams.toString();
    }, [searchParams]);
    // Update query string
    React.useEffect(() => {
        var _a, _b;
        if (debounceValue.length > 0) {
            navigate(`?${createQueryString({
                [(_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) !== null && _a !== void 0 ? _a : ""]: `${debounceValue}${debounceValue.length > 0 ? `.${filterVariety}` : ""}`,
            })}`);
        }
        if (debounceValue.length === 0) {
            navigate(`?${createQueryString({
                [(_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) !== null && _b !== void 0 ? _b : ""]: null,
            })}`);
        }
    }, [debounceValue, filterVariety, selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value]);
    // Update operator query string
    React.useEffect(() => {
        if (operator === null || operator === void 0 ? void 0 : operator.value) {
            navigate(`?${createQueryString({
                operator: operator.value,
            })}`);
        }
    }, [operator === null || operator === void 0 ? void 0 : operator.value]);
    return (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [i === 0 ? (_jsx("div", { children: "Where" })) : i === 1 ? (_jsxs(Select, Object.assign({ value: operator === null || operator === void 0 ? void 0 : operator.value, onValueChange: (value) => setOperator(dataTableConfig.logicalOperators.find((o) => o.value === value)) }, { children: [_jsx(SelectTrigger, Object.assign({ className: "h-8 w-fit text-xs" }, { children: _jsx(SelectValue, { placeholder: operator === null || operator === void 0 ? void 0 : operator.label }) })), _jsx(SelectContent, { children: _jsx(SelectGroup, { children: dataTableConfig.logicalOperators.map((operator) => (_jsx(SelectItem, Object.assign({ value: operator.value, className: "text-xs" }, { children: operator.label }), operator.value))) }) })] }))) : (_jsx("div", { children: operator === null || operator === void 0 ? void 0 : operator.label }, operator === null || operator === void 0 ? void 0 : operator.value)), _jsxs(Select, Object.assign({ value: String(selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value), onValueChange: (value) => {
                    setSelectedOption(allOptions.find((option) => option.value === value));
                    setSelectedOptions((prev) => prev.map((item) => {
                        if (item.id === option.id) {
                            return Object.assign(Object.assign({}, item), { value: value });
                        }
                        return item;
                    }));
                } }, { children: [_jsx(SelectTrigger, Object.assign({ className: "h-8 w-full text-xs capitalize" }, { children: _jsx(SelectValue, { placeholder: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label }) })), _jsx(SelectContent, { children: _jsx(SelectGroup, { children: allOptions.map((option) => (_jsx(SelectItem, Object.assign({ value: String(option.value), className: "text-xs capitalize" }, { children: option.label }), String(option.value)))) }) })] })), _jsxs(Select, Object.assign({ value: filterVariety, onValueChange: (value) => setFilterVariety(value) }, { children: [_jsx(SelectTrigger, Object.assign({ className: "h-8 w-full truncate px-2 py-0.5 hover:bg-muted-dark" }, { children: _jsx(SelectValue, { placeholder: filterVarieties[0] }) })), _jsx(SelectContent, { children: _jsx(SelectGroup, { children: filterVarieties.map((variety) => (_jsx(SelectItem, Object.assign({ value: variety }, { children: variety }), variety))) }) })] })), (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.options.length) ? (table.getColumn(selectedOption.value ? String(option.value) : "") && (_jsx(DataTableFacetedFilter, { column: table.getColumn(selectedOption.value ? String(selectedOption.value) : ""), title: selectedOption.label, options: selectedOption.options }, selectedOption.id))) : (_jsx("input", { placeholder: "Type here...", className: "h-8", value: value, onChange: (event) => setValue(event.target.value), autoFocus: true })), _jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "h-8 w-8 shrink-0" }, { children: _jsx(EllipsisHorizontalIcon, { className: "h-4 w-4", "aria-hidden": "true" }) })) })), _jsxs(DropdownMenuContent, { children: [_jsxs(DropdownMenuItem, Object.assign({ onClick: () => {
                                    setSelectedOptions((prev) => prev.filter((item) => item.id !== option.id));
                                } }, { children: [_jsx(TrashIcon, { className: "mr-2 h-4 w-4", "aria-hidden": "true" }), "Remove"] })), _jsxs(DropdownMenuItem, Object.assign({ onClick: () => {
                                    if (!selectedOption)
                                        return;
                                    setSelectedOptions((prev) => {
                                        var _a;
                                        return [
                                            ...prev,
                                            {
                                                id: crypto.randomUUID(),
                                                label: selectedOption.label,
                                                value: selectedOption.value,
                                                options: (_a = selectedOption.options) !== null && _a !== void 0 ? _a : [],
                                                isMulti: true,
                                            },
                                        ];
                                    });
                                } }, { children: [_jsx(ClipboardIcon, { className: "mr-2 h-4 w-4", "aria-hidden": "true" }), "Duplicate"] }))] })] })] })));
}
