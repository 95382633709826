var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button } from "./button";
import { DataTableFacetedFilter } from "./data_table_faceted_filter";
import { DataTableViewOptions } from "./data_table_view_options";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
/**
 * Data table toolbar to show simple filters
 * These filters will change the query params in the URL
 * Only 2 types of filters are supported:
 * Search filter
 * Faceted filter (selectable filter)
 * @param param0
 * @returns
 */
export function DataTableToolbar(_a) {
    var { table, filterFields = [], asyncFilter, children, className, hasOtherFilters, setResetFlag, setAsyncFilterFlag, columnIdMap } = _a, props = __rest(_a, ["table", "filterFields", "asyncFilter", "children", "className", "hasOtherFilters", "setResetFlag", "setAsyncFilterFlag", "columnIdMap"]);
    const isFiltered = table.getState().columnFilters.length > 0 || hasOtherFilters;
    // Memoize computation of searchableColumns and filterableColumns
    const { searchableColumns, filterableColumns } = React.useMemo(() => {
        return {
            searchableColumns: filterFields.filter((field) => !field.options),
            filterableColumns: filterFields.filter((field) => field.options),
        };
    }, [filterFields]);
    const resetAllFilters = () => {
        table.resetColumnFilters();
        // Need to use 2 filters, one is for the date
        // The other is for the asyncsearch select
        // Both have to be reset, same state cannot be used to track both
        setResetFlag(true);
        setAsyncFilterFlag(true);
    };
    return (_jsxs("div", { children: [asyncFilter && (_jsx("div", Object.assign({ className: classNames("flex w-full items-center justify-between space-x-2 p-1", className) }, { children: asyncFilter }))), _jsxs("div", Object.assign({ className: classNames("flex w-full items-center justify-between space-x-2 p-1", className) }, props, { children: [_jsxs("div", Object.assign({ className: "flex flex-1 items-center space-x-2" }, { children: [searchableColumns.length > 0 &&
                                searchableColumns.map((column) => {
                                    var _a, _b;
                                    return table.getColumn(column.value ? String(column.value) : "") && (_jsxs("div", Object.assign({ className: "w-60 px-3 bg-white rounded-lg border border-zinc-200 justify-start items-center flex" }, { children: [_jsx(MagnifyingGlassIcon, { className: "text-gray-400 w-5 h-5 relative" }), _jsx("input", { className: "focus:outline-none border-0 focus:ring-0 placeholder:text-gray-400 text-sm", placeholder: column.placeholder, value: (_b = (_a = table
                                                    .getColumn(String(column.value))) === null || _a === void 0 ? void 0 : _a.getFilterValue()) !== null && _b !== void 0 ? _b : "", onChange: (event) => {
                                                    var _a;
                                                    return (_a = table
                                                        .getColumn(String(column.value))) === null || _a === void 0 ? void 0 : _a.setFilterValue(event.target.value);
                                                } }, String(column.value))] })));
                                }), filterableColumns.length > 0 &&
                                filterableColumns.map((column) => {
                                    var _a;
                                    return table.getColumn(column.value ? String(column.value) : "") && (_jsx(DataTableFacetedFilter, { column: table.getColumn(column.value ? String(column.value) : ""), title: column.label, options: (_a = column.options) !== null && _a !== void 0 ? _a : [] }, String(column.value)));
                                }), isFiltered && (_jsxs(Button, Object.assign({ "aria-label": "Reset filters", variant: "ghost", className: "h-8 px-2 lg:px-3 text-wds-gray-5", onClick: () => resetAllFilters() }, { children: ["Reset", _jsx(XMarkIcon, { className: "ml-2 h-4 w-4", "aria-hidden": "true" })] })))] })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [children, _jsx(DataTableViewOptions, { table: table, columnIdMap: columnIdMap })] }))] }))] }));
}
