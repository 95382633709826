var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { SelectTrigger } from "@radix-ui/react-select";
import { exportTableToCSV } from "../table_helpers";
import { Button } from "../components/button";
import { Select, SelectContent, SelectGroup, SelectItem, } from "../components/select";
import { Separator } from "../components/separator";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { Kbd } from "../components/kbd";
import { ArrowDownTrayIcon, ArrowPathIcon, CheckCircleIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { updateCallType } from "../common/endpoints";
import { useQueryClient } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { convertSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
export function CallsTableFloatingBar({ table }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const newFilterQuery = convertSearchParamsIntoFilterQuery(searchParams, location);
    const page = parseInt(searchParams.get("page") || "1") || 1;
    const perPage = parseInt(searchParams.get("per_page") || "10") || 10;
    const queryClient = useQueryClient();
    const rows = table.getFilteredSelectedRowModel().rows;
    const [isPending, setIsPending] = React.useState(false);
    const [method, setMethod] = React.useState();
    // Clear selection on Escape key press
    React.useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === "Escape") {
                table.toggleAllRowsSelected(false);
            }
        }
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [table]);
    return (_jsx("div", Object.assign({ className: "fixed inset-x-0 bottom-4 z-50 mx-auto w-fit px-4" }, { children: _jsx("div", Object.assign({ className: "w-full overflow-x-auto" }, { children: _jsxs("div", Object.assign({ className: "mx-auto flex w-fit items-center gap-2 rounded-md border bg-card p-2 shadow-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex h-7 items-center rounded-md border border-dashed pl-2.5 pr-1" }, { children: [_jsxs("span", Object.assign({ className: "whitespace-nowrap text-xs" }, { children: [rows.length, " selected"] })), _jsx(Separator, { orientation: "vertical", className: "ml-2 mr-1" }), _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "h-5 w-5 hover:border", onClick: () => table.toggleAllRowsSelected(false) }, { children: _jsx(XMarkIcon, { className: "h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }) })) })), _jsxs(TooltipContent, Object.assign({ className: "flex items-center border bg-accent px-2 py-1 font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: [_jsx("p", Object.assign({ className: "mr-2" }, { children: "Clear selection" })), _jsx(Kbd, Object.assign({ abbrTitle: "Escape", variant: "outline" }, { children: "Esc" }))] }))] }) })] })), _jsx(Separator, { orientation: "vertical", className: "hidden h-5 sm:block" }), _jsxs("div", Object.assign({ className: "flex items-center gap-1.5" }, { children: [_jsxs(Select, Object.assign({ onValueChange: (value) => __awaiter(this, void 0, void 0, function* () {
                                    setMethod("update-type");
                                    setIsPending(true);
                                    yield updateCallType(rows.map((row) => row.original.id), value);
                                    // refetch the data to update the selected rows
                                    queryClient.invalidateQueries([
                                        "callsTable",
                                        newFilterQuery,
                                        page,
                                        perPage,
                                    ]);
                                    setIsPending(false);
                                }) }, { children: [_jsx(TooltipProvider, { children: _jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(SelectTrigger, Object.assign({ asChild: true }, { children: _jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "secondary", size: "icon", className: "h-7 w-7 border data-[state=open]:bg-accent data-[state=open]:text-accent-foreground", disabled: isPending }, { children: isPending && method === "update-type" ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin", "aria-hidden": "true" })) : (_jsx(CheckCircleIcon, { className: "h-3.5 w-3.5", "aria-hidden": "true" })) })) })) })), _jsx(TooltipContent, Object.assign({ className: "border bg-accent font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: _jsx("p", { children: "Update Call type" }) }))] })) }), _jsx(SelectContent, Object.assign({ align: "center" }, { children: _jsx(SelectGroup, { children: ["Internal", "External"].map((status) => (_jsx(SelectItem, Object.assign({ value: status, className: "capitalize" }, { children: status }), status))) }) }))] })), _jsx(TooltipProvider, { children: _jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "secondary", size: "icon", className: "h-7 w-7 border", onClick: () => {
                                                    setMethod("export");
                                                    setIsPending(true);
                                                    exportTableToCSV(table, {
                                                        excludeColumns: ["select", "actions"],
                                                        onlySelected: true,
                                                    });
                                                    setIsPending(false);
                                                }, disabled: isPending }, { children: isPending && method === "export" ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin", "aria-hidden": "true" })) : (_jsx(ArrowDownTrayIcon, { className: "h-3.5 w-3.5", "aria-hidden": "true" })) })) })), _jsx(TooltipContent, Object.assign({ className: "border bg-accent font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: _jsx("p", { children: "Export calls" }) }))] })) })] }))] })) })) })));
}
