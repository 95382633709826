var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowPathIcon, CheckIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ClipboardDocumentListIcon, } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
import WaitingSpinner from "../waiting_spinner";
import AddPlaybookModal from "../add_playbook_modal";
import { useStreamSocket, } from "../common/stream_socket";
import classNames from "../class_names";
import tw from "tailwind-styled-components";
import { Tab } from "@headlessui/react";
import WiserSparklesIcon from "../wiser_sparkles_icon";
import { TrackerNotesCopyButton, TrackerNotesDownvoteButton, TrackerNotesRegenerateButton, TrakcerNotesUpvoteButton, } from "./tracker_notes_action_buttons";
import Dropdown from "../common/dropdown";
import { NoteLoaderFrame } from "../common/loaders";
// Styled components
const TrackerContainer = tw.div `
    flex
    flex-col 
    gap-4
    h-full
    pb-4
`;
const TrackerHeaderContainer = tw.div `
    flex
    py-2
    px-8
    justify-between
    items-center
    self-stretch
    bg-wds-gray-2
    border-b
    border-b-gray-300
`;
const TrackerChangePlaybookButtonContainer = tw.div `
    flex
    w-8
    h-8
    items-center
    justify-center
`;
const TrackerTitleContainer = tw.div `
    flex
    items-center
    gap-2
`;
const TrackerRegenerateNotesButtonContainer = tw.div `
    flex
    w-8
    h-8
    items-center
    justify-center
`;
const TrackerBodyContainer = tw.div `
    flex
    flex-col
    md:flex-row
    px-4
    items-start
    self-stretch
    h-full
    md:overflow-hidden
`;
const TrackerItemsPanelContainer = tw.div `
    relative
    flex
    flex-col
    lg:w-[300px]
    items-start
    self-stretch
    grow
    shrink-0
    rounded-lg
    border
    border-wds-gray-3
`;
const TrackerItemScoreContainer = tw.div `
    rounded-full
    overflow-hidden
    inline-flex
    justify-start
    items-start
    gap-px
    shrink-0
`;
const TrackerItemScoreBlock = tw.div `
    w-3 h-4
`;
const TrackerNotesContainer = tw.div `
    relative
    flex
    flex-col
    gap-2
    grow
    pt-2
    px-6
    items-start
    self-stretch
    w-full
    overflow-hidden
`;
const TrackerNotesHeaderContainer = tw.div `
    flex
    flex-col
    md:flex-row
    justify-between
    items-start
    md:items-center
    gap-2
    md:gap-0
    self-stretch
    shrink-0
    overflow-hidden
`;
const TrackerNotesBodyContainer = tw.div `
    flex
    flex-col
    gap-2
    items-start
    self-stretch
    pt-1
    overflow-y-auto
`;
const TrackerItemNotes = tw.div `
    flex
    flex-col
    text-black
    font-lato
    text-sm
    font-normal
    pb-10
`;
const TrackerNotesActionsContainer = tw.div `
    flex
    items-start
    md:justify-end
`;
const getPlaybookScoresMap = (note) => {
    let playbookScoreColors = ["wds-gray-2", "wds-gray-2", "wds-gray-2"];
    if (note.notes.length > 0) {
        let onlyNote = note.notes[0];
        if (onlyNote.score >= 0.8) {
            playbookScoreColors = ["wds-green-1", "wds-green-1", "wds-green-1"];
        }
        else if (onlyNote.score >= 0.6) {
            playbookScoreColors = ["wds-lime-1", "wds-lime-1", "wds-gray-2"];
        }
        else if (onlyNote.score >= 0.1) {
            playbookScoreColors = ["wds-yellow-1", "wds-gray-2", "wds-gray-2"];
        }
    }
    return playbookScoreColors;
};
const TrackerItemTab = ({ note }) => {
    return (_jsxs(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-4 py-2 self-stretch justify-between items-center gap-4 border-wds-blue-3 items-center flex focus:outline-none focus:ring-0", selected
            ? "border-l-2 font-bold bg-wds-gray-1"
            : "hover:bg-blue-100 font-normal") }, { children: [_jsx("span", Object.assign({ className: "overflow-hidden text-ellipsis whitespace-nowrap break-all truncate" }, { children: note.question })), _jsx(TrackerItemScoreContainer, { children: getPlaybookScoresMap(note).map((color, idx) => (_jsx(TrackerItemScoreBlock, { className: "w-3 h-4 bg-" + color }, idx))) })] }), note.playbook_item_id));
};
const TrackerItemsPanel = ({ notes, onTrackerItemClick, selectedTrackerItemId, trackerItemsPanelCollapsed, }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    useEffect(() => {
        setIsCollapsed(trackerItemsPanelCollapsed);
    }, [trackerItemsPanelCollapsed]);
    const [showCollapseButton, setShowCollapseButton] = useState(false);
    const selectedIndex = Math.max(0, notes.findIndex((note) => note.playbook_item_id === selectedTrackerItemId));
    const trackerItemDropdownOptionRenderer = (option, selected) => {
        const index = parseInt(option);
        const note = notes[index];
        const selectedClasses = selected === option ? "font-bold" : "";
        return (_jsxs("div", Object.assign({ className: classNames("flex items-center justify-between self-stretch gap-2 w-full", selectedClasses) }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 items-center justify-center" }, { children: [_jsx(TrackerItemScoreContainer, { children: getPlaybookScoresMap(note).map((color, idx) => (_jsx(TrackerItemScoreBlock, { className: `w-3 h-4 bg-${color}` }, idx))) }), _jsx("span", Object.assign({ className: "overflow-hidden text-ellipsis whitespace-nowrap break-all truncate" }, { children: note.question }))] })), option === selected && (_jsx("div", Object.assign({ className: "self-center place-self-end justify-end" }, { children: _jsx(CheckIcon, { className: "h-4 w-4 text-wds-blue-4" }) })))] })));
    };
    if (notes.length === 0) {
        // We don't have an empty state UI for trackers yet, so rendering nothing.
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex md:hidden px-6 w-full" }, { children: _jsx(Dropdown, { options: notes.map((note, index) => ({
                        label: note.question,
                        value: `${index}`,
                    })), defaultSelected: `${selectedIndex}`, onOptionSelect: (option) => {
                        const index = parseInt(option);
                        onTrackerItemClick(notes[index].playbook_item_id);
                    }, selectedOption: `${selectedIndex}`, buttonClasses: "grow hover:none bg-white border border-wds-gray-3 rounded-lg", optionRenderer: trackerItemDropdownOptionRenderer }) })), _jsx("div", Object.assign({ className: "h-full hidden md:flex" }, { children: isCollapsed ? (_jsx("div", Object.assign({ className: "flex items-start self-stretch grow shrink-0 rounded-lg border border-wds-gray-3 py-4 px-2 hover:cursor-pointer", onClick: () => setIsCollapsed(false) }, { children: _jsx("div", Object.assign({ className: "hover:bg-wds-gray-2 rounded-full p-2" }, { children: _jsx(ChevronDoubleRightIcon, { className: "h-5 w-5 text-wds-gray-5" }) })) }))) : (_jsxs(TrackerItemsPanelContainer, Object.assign({ onMouseEnter: () => setShowCollapseButton(true), onMouseLeave: () => setShowCollapseButton(false) }, { children: [_jsx(Tab.Group, Object.assign({ vertical: true, onChange: (index) => {
                                onTrackerItemClick(notes[index].playbook_item_id);
                            }, selectedIndex: selectedIndex }, { children: _jsx(Tab.List, Object.assign({ className: "flex flex-col items-start self-stretch overflow-y-auto" }, { children: notes.map((note) => (_jsx(TrackerItemTab, { note: note }, note.playbook_item_id))) })) })), showCollapseButton && (_jsx("div", Object.assign({ className: "absolute top-4 -right-4 z-10 bg-white border border-wds-gray-3 hover:bg-wds-gray-2 text-wds-gray-5 hover:text-wds-blue-3 rounded-full p-2 hover:cursor-pointer", onClick: () => setIsCollapsed(true) }, { children: _jsx(ChevronDoubleLeftIcon, { className: "h-5 w-5" }) })))] }))) }))] }));
};
const TrackerItemSuggestionButton = ({ note, generateRecommendedQuestions, showWaitingSpinnerForRecommendation, postCall, }) => {
    if (postCall) {
        return null;
    }
    return (_jsxs("button", Object.assign({ onClick: () => generateRecommendedQuestions(note.playbook_item_id), className: "flex px-4 py-2 gap-2 items-center border border-wds-gray-3 rounded-lg shrink-0", disabled: showWaitingSpinnerForRecommendation }, { children: [showWaitingSpinnerForRecommendation ? (_jsx(WaitingSpinner, { text: "", classes: "self-center" })) : (_jsx(WiserSparklesIcon, {})), _jsx("span", Object.assign({ className: "text-sm font-medium" }, { children: "Suggest questions to ask" }))] })));
};
const TrackerNotesPanel = ({ note, generateRecommendedQuestions, showWaitingSpinnerForRecommendation, regenerateNotes, noteRegenerationInProgress, postCall, }) => {
    if (!note)
        return null;
    const contentLine = note.notes.map((note) => note.content).join("\n");
    const renderNoContentBody = () => {
        return (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "text-sm font-normal text-wds-gray-5" }, { children: "No content yet\u2014try asking Wiser for questions to ask." })), _jsx("div", Object.assign({ className: "hidden md:inline" }, { children: _jsx(TrackerItemSuggestionButton, { note: note, generateRecommendedQuestions: generateRecommendedQuestions, showWaitingSpinnerForRecommendation: showWaitingSpinnerForRecommendation, postCall: postCall }) }))] }));
    };
    const renderTrackerItemNotes = () => {
        return (_jsx(_Fragment, { children: contentLine.trim() !== "" ? (_jsxs(_Fragment, { children: [_jsx(TrackerItemNotes, { children: _jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: contentLine.trim() })) }), _jsx("div", Object.assign({ className: "hidden md:inline absolute z-10 bg-white right-4 bottom-0" }, { children: _jsx(TrackerItemSuggestionButton, { note: note, generateRecommendedQuestions: generateRecommendedQuestions, showWaitingSpinnerForRecommendation: showWaitingSpinnerForRecommendation, postCall: postCall }) }))] })) : (renderNoContentBody()) }));
    };
    return (_jsxs(TrackerNotesContainer, { children: [_jsxs(TrackerNotesHeaderContainer, { children: [_jsx("span", Object.assign({ className: "text-base text-black font-bold md:truncate" }, { children: note.question })), _jsxs("div", Object.assign({ className: "flex flex-row md:inline items-center justify-between md:justify-start self-stretch" }, { children: [_jsxs(TrackerNotesActionsContainer, { children: [_jsx(TrakcerNotesUpvoteButton, { note: note }), _jsx(TrackerNotesDownvoteButton, { note: note }), contentLine.trim() !== "" && (_jsx(TrackerNotesCopyButton, { content: contentLine.trim() })), _jsx(TrackerNotesRegenerateButton, { note: note, onRegenerateNotes: regenerateNotes, noteRegenerationInProgress: noteRegenerationInProgress })] }), _jsx("div", Object.assign({ className: "md:hidden" }, { children: _jsx(TrackerItemSuggestionButton, { note: note, generateRecommendedQuestions: generateRecommendedQuestions, showWaitingSpinnerForRecommendation: showWaitingSpinnerForRecommendation, postCall: postCall }) }))] }))] }), _jsx(TrackerNotesBodyContainer, { children: renderTrackerItemNotes() })] }));
};
const TrackerV2 = (props) => {
    const { call, notes, setCallAndNotes } = props;
    const playbookId = call.playbook_id;
    const [playbook, setPlaybook] = useState(null);
    const [addPlaybookModalOpen, setAddPlaybookModalOpen] = useState(false);
    const [selectedTrackerItemId, setSelectedTrackerItemId] = useState(null);
    const streamSocket = useStreamSocket();
    // Map of playbook item id to timestamp at which recommendation was requested.
    // The reason this state is in the parent rather than TrackerNotesPanel is
    // we need to ensure that switching tracker item does not lose the state.
    // TODO: When we cleanup the old Playbook componenet, we should move this state
    // to LiveCall/index, and make it a bool.
    const [recommendationRequestTimeMsecs, setRecommendationRequestTimeMsecs] = useState(new Map());
    const [lastNoteGenerationRequestTime, setLastNoteGenerationRequestTime] = useState(0);
    // Map from tracker item id to a boolean indicating whether user has requested
    // regeneration of notes using GPT-4 for that tracker item.
    const [trackerNoteGenerationInProgress, setTrackerNoteGenerationInProgress] = useState(new Map());
    useEffect(() => {
        streamSocket.addListener("display_notes", handleNotesResponse);
    }, []);
    const handleNotesResponse = (response) => {
        if (!response.display_notes)
            return;
        props.updateNotesMap(response.display_notes);
        // Reset the manual note generation progress states.
        setLastNoteGenerationRequestTime(0);
        setTrackerNoteGenerationInProgress(new Map());
    };
    const generateRecommendedQuestions = (playbookItemId) => __awaiter(void 0, void 0, void 0, function* () {
        streamSocket.send(JSON.stringify({
            type: "register_user_trigger",
            trigger_type: "RECOMMEND_PLAYBOOK_QUESTIONS",
            playbook_item_id: playbookItemId,
        }));
        setRecommendationRequestTimeMsecs((prev) => new Map(prev.set(playbookItemId, Date.now())));
        props.goToChatTab();
    });
    const showWaitingSpinnerForRecommendation = (playbookItemId) => {
        if (recommendationRequestTimeMsecs.has(playbookItemId) &&
            !props.lastRecommendationReceivedTimeMsecs.has(playbookItemId)) {
            return true;
        }
        const recommendationRequestedTime = recommendationRequestTimeMsecs.get(playbookItemId);
        const lastRecommendationReceivedTime = props.lastRecommendationReceivedTimeMsecs.get(playbookItemId);
        return recommendationRequestedTime > lastRecommendationReceivedTime;
    };
    const regenerateNotes = (playbookItemId) => __awaiter(void 0, void 0, void 0, function* () {
        const regenerate_command_json = {
            type: "register_user_trigger",
            trigger_type: "GPT4_NOTE_GENERATION",
        };
        if (playbookItemId) {
            Object.assign(regenerate_command_json, {
                playbook_item_id: playbookItemId,
            });
        }
        streamSocket.send(JSON.stringify(regenerate_command_json));
        // Only set the note generation request time, when the user
        // requests for all tracker items
        if (!playbookItemId) {
            setLastNoteGenerationRequestTime(Date.now());
        }
        else {
            setTrackerNoteGenerationInProgress((prevMap) => {
                const updatedMap = new Map(prevMap);
                updatedMap.set(playbookItemId, true);
                return updatedMap;
            });
        }
    });
    useEffect(() => {
        endpoints.getPlaybook(playbookId).then((playbook) => {
            setPlaybook(playbook);
        });
    }, [playbookId]);
    const playbook_item_ids_to_render = useMemo(() => {
        if (props.postCall && playbook) {
            return playbook.playbook_item_groups.map((group) => group.default_item.id);
        }
        if (playbook) {
            // Only LIVE_CALL playbook items are rendered in the live call view.
            const live_call_playbook_item_ids = playbook.playbook_item_groups
                .filter((group) => group.default_item.call_stage === "LIVE_CALL")
                .map((group) => group.default_item.id);
            return live_call_playbook_item_ids;
        }
        return [];
    }, [playbook]);
    useEffect(() => {
        if (selectedTrackerItemId !== null)
            return;
        if (playbook_item_ids_to_render.length === 0)
            return;
        setSelectedTrackerItemId(playbook_item_ids_to_render[0]);
    }, [playbook_item_ids_to_render]);
    const selectedNote = useMemo(() => {
        return notes.find((note) => note.playbook_item_id === selectedTrackerItemId);
    }, [selectedTrackerItemId, notes]);
    if (!playbook || !selectedTrackerItemId) {
        return _jsx(NoteLoaderFrame, {});
    }
    return (_jsxs(TrackerContainer, { children: [_jsxs(TrackerHeaderContainer, { children: [_jsxs(TrackerTitleContainer, { children: [_jsx("button", Object.assign({ onClick: () => setAddPlaybookModalOpen(true), className: "hover:bg-gray-300 rounded-full" }, { children: _jsx(TrackerChangePlaybookButtonContainer, { children: _jsx(ClipboardDocumentListIcon, { className: "h-6 w-6 text-black" }) }) })), _jsx("span", Object.assign({ className: "font-bold text-lg text-black overflow-hidden truncate text-ellipsis whitespace-nowrap" }, { children: playbook.name }))] }), !props.postCall && (_jsx("button", Object.assign({ onClick: () => regenerateNotes(), className: "hover:bg-gray-300 rounded-full", disabled: lastNoteGenerationRequestTime > 0 }, { children: _jsx(TrackerRegenerateNotesButtonContainer, { children: _jsx(ArrowPathIcon, { className: classNames("h-5 w-5 text-gray-500", lastNoteGenerationRequestTime > 0 ? "animate-spin" : "") }) }) })))] }), _jsx(AddPlaybookModal, { addPlaybookModalOpen: addPlaybookModalOpen, setAddPlaybookModalOpen: setAddPlaybookModalOpen, addPlaybook: (playbook) => __awaiter(void 0, void 0, void 0, function* () {
                    streamSocket.send(JSON.stringify({
                        type: "set_current_playbook",
                        playbook_id: playbook.id,
                    }));
                    const response = yield endpoints.getLiveCall(call.id);
                    setCallAndNotes(response);
                }), selectedPlaybookId: call.playbook_id }), _jsxs(TrackerBodyContainer, { children: [_jsx(TrackerItemsPanel, { notes: notes.filter((note) => playbook_item_ids_to_render.includes(note.playbook_item_id)), onTrackerItemClick: (trackerItemId) => {
                            setSelectedTrackerItemId(trackerItemId);
                        }, selectedTrackerItemId: selectedTrackerItemId, trackerItemsPanelCollapsed: props.trackerItemsPanelCollapsed }), _jsx(TrackerNotesPanel, { note: selectedNote, generateRecommendedQuestions: generateRecommendedQuestions, showWaitingSpinnerForRecommendation: selectedNote
                            ? showWaitingSpinnerForRecommendation(selectedNote.playbook_item_id)
                            : false, regenerateNotes: regenerateNotes, noteRegenerationInProgress: selectedNote
                            ? !!trackerNoteGenerationInProgress.get(selectedNote.playbook_item_id)
                            : false, postCall: props.postCall })] })] }));
};
export default TrackerV2;
