var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { exportTableToCSV } from "../table_helpers";
import { Button } from "../components/button";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "react-query";
import { useState } from "react";
export function CallsTableToolbarActions({ table, }) {
    const queryClient = useQueryClient();
    const [exporting, setExporting] = useState(false);
    return (_jsx("div", Object.assign({ className: "flex items-center gap-2" }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", disabled: exporting, onClick: () => __awaiter(this, void 0, void 0, function* () {
                setExporting(true);
                table.setPageSize(1000);
                yield queryClient.invalidateQueries();
                exportTableToCSV(table, {
                    filename: "calls",
                    excludeColumns: ["select", "actions", "playbook_items"],
                });
                setExporting(false);
            }), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: [!exporting ? (_jsx(ArrowDownTrayIcon, { className: "mr-2 h-4 w-4", "aria-hidden": "true" })) : (_jsx(ArrowPathIcon, { className: "mr-2 h-4 w-4 animate-spin", "aria-hidden": "true" })), "Export"] })) })));
}
