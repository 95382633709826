import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CallKeypoints } from "./call_key_points";
import { CallNextSteps } from "./call_next_steps";
import { CallBrief } from "./call_brief";
import { useStreamSocket, } from "../common/stream_socket";
import { useEffect, useState } from "react";
import { useCallHighlights } from "./use_call_highlights";
export const CallHighlights = ({ call }) => {
    const streamSocket = useStreamSocket();
    const [regeneratingCallHighlights, setRegeneratingCallHighlights] = useState(false);
    const { invalidateCallHighlights } = useCallHighlights(call.id);
    useEffect(() => {
        streamSocket.addListener("end_of_call_summary_user_trigger", handleCallHighlightsResponse);
    }, []);
    const regenerateCallHighlights = () => {
        streamSocket.send(JSON.stringify({
            type: "register_user_trigger",
            trigger_type: "GENERATE_END_OF_CALL_SUMMARY",
        }));
        setRegeneratingCallHighlights(true);
    };
    const handleCallHighlightsResponse = (_) => {
        invalidateCallHighlights();
        setRegeneratingCallHighlights(false);
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-start" }, { children: [_jsx(CallBrief, { call: call, regeneratingCallHighlights: regeneratingCallHighlights, regenerateCallHighlights: regenerateCallHighlights }), _jsx(CallKeypoints, { call: call, regeneratingCallHighlights: regeneratingCallHighlights }), _jsx(CallNextSteps, { call: call, regeneratingCallHighlights: regeneratingCallHighlights })] })));
};
