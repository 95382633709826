var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { EllipsisVerticalIcon, PencilIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { Popover } from "@headlessui/react";
import { confirmModal } from "./common/confirmation_modal";
import EditGoalModal from "./edit_goal";
import AddPlaybookItemGroupModal from "./home/add_playbook_item_group_modal";
import { addGroupToPlaybook, deletePlaybookItemGroup, getPlaybookItemGroups, removeGroupFromPlaybook, reorderPlaybookGoals, updatePlaybook, } from "./common/endpoints";
import { DragDropContext, Droppable, Draggable, } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import BaseModal from "./base_modal";
export function callStageDisplayName(stage) {
    switch (stage) {
        case "LIVE_CALL":
            return "Live";
        case "POST_CALL":
            return "Post-Call";
        case "FOLLOW_UP_EMAIL":
            return "Follow-up email";
        default:
            return stage;
    }
}
const EditPlaybook = ({ playbook, refetchCallback }) => {
    var _a, _b, _c;
    const location = useLocation();
    // copying props to state for easier manipulation, updates are sent back to parent by refetchCallback
    const [playbookItemGroups, setPlaybookItemGroups] = useState(playbook.playbook_item_groups);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState("");
    const [editingPlaybookGoalId, setEditingPlaybookGoalId] = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.editingPlaybookGroupId) || null);
    const [showAddPlaybookItemGroupModal, setShowAddPlaybookItemGroupModal] = useState(false);
    const [allPlaybookItemGroups, setAllPlaybookItemGroups] = useState([]);
    const [creatingPlaybookItemGroup, setCreatingPlaybookItemGroup] = useState((_c = (_b = location.state) === null || _b === void 0 ? void 0 : _b.creatingPlaybookItemGroup) !== null && _c !== void 0 ? _c : false);
    const [playbookItemGroupDeleteErrors, setPlaybookItemGroupDeleteErrors] = useState(null);
    const [playbookItemGroupIdToBeForceDeleted, setPlaybookItemGroupIdToBeForceDeleted,] = useState(null);
    const fetchAllPlaybookItemGroups = () => {
        getPlaybookItemGroups().then(setAllPlaybookItemGroups);
    };
    useEffect(() => {
        fetchAllPlaybookItemGroups();
    }, []);
    useEffect(() => {
        setPlaybookItemGroups(playbook.playbook_item_groups);
    }, [playbook.playbook_item_groups]);
    const updatePlaybookName = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!name)
            return; // todo show validation error
        // need to construct the details since it is required by the updatePlaybook endpoint
        const details = JSON.parse(playbook.details);
        yield updatePlaybook(playbook.id, name, {
            teams: details.teams,
            relevantStages: details.relevantStages,
        });
        refetchCallback();
    });
    useEffect(() => {
        setName(playbook.name);
    }, [playbook.name]);
    const onDragEnd = (result) => __awaiter(void 0, void 0, void 0, function* () {
        if (!result.destination)
            return;
        const reOrderedGroups = [...playbookItemGroups];
        const [removedGroup] = reOrderedGroups.splice(result.source.index, 1);
        reOrderedGroups.splice(result.destination.index, 0, removedGroup);
        setPlaybookItemGroups(reOrderedGroups);
        yield reorderPlaybookGoals(playbook.id, reOrderedGroups.map((group) => group.id));
        refetchCallback();
    });
    return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pb-4 border-b border-wds-gray-3 flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Details" })), !isEditing ? (_jsxs("button", Object.assign({ className: "px-3 py-1 rounded-full justify-start items-center gap-2 flex hover:bg-wds-blue-1", onClick: () => {
                                    setIsEditing(true);
                                } }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Edit" })), _jsx(PencilIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))) : null] })), _jsxs("div", Object.assign({ className: "w-full flex-col justify-start items-start gap-1 flex" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 text-xs font-medium leading-none" }, { children: "Name" })), isEditing ? (_jsx("input", { type: "text", value: name !== null && name !== void 0 ? name : "", onChange: (e) => setName(e.target.value), className: "w-full h-9 px-1 bg-white shadow border-t rounded-lg border-wds-gray-3 justify-start items-center gap-1 inline-flex", onBlur: () => {
                                    updatePlaybookName();
                                    setIsEditing(false);
                                }, autoFocus: true })) : (_jsx("div", Object.assign({ className: "text-black leading-snug" }, { children: name })))] }))] })), _jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Trackers" })), _jsxs("button", Object.assign({ className: "px-3 py-1 rounded-full justify-start items-center gap-2 flex hover:bg-wds-blue-1", onClick: () => {
                            setShowAddPlaybookItemGroupModal(true);
                        } }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Add" })), _jsx(PlusIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))] })), _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "playbook-item-groups" }, { children: ({ droppableProps, innerRef, placeholder }) => {
                        return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-2 flex" }, droppableProps, { ref: innerRef }, { children: [playbookItemGroups.map((group, index) => (_jsx(Draggable, Object.assign({ draggableId: group.id.toString(), index: index }, { children: ({ draggableProps, dragHandleProps, innerRef }) => {
                                        return (_jsxs("div", Object.assign({ className: "relative self-stretch pl-4 pr-2 py-4 bg-white rounded-lg border border-neutral-200 justify-end items-start gap-2 inline-flex" }, draggableProps, dragHandleProps, { ref: innerRef }, { children: [_jsxs("div", Object.assign({ className: "grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex" }, { children: [_jsx("div", Object.assign({ className: "self-stretch text-neutral-950 font-medium leading-tight" }, { children: group.name })), _jsx("div", Object.assign({ className: "px-2 py-0.5 bg-slate-50 rounded-lg border border-wds-gray-3 justify-start items-center gap-1 inline-flex" }, { children: _jsx("div", Object.assign({ className: "text-neutral-700 text-xs font-medium leading-none" }, { children: callStageDisplayName(group.default_item.call_stage) })) }))] })), _jsxs(Popover, Object.assign({ as: "div" }, { children: [_jsx(Popover.Button, Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-wds-blue-1" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-4 h-4" }) })), _jsx(Popover.Panel, Object.assign({ className: "absolute right-[40px] z-10 w-46 py-2 bg-white rounded-lg shadow border border-neutral-200 flex-col inline-flex" }, { children: _jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-wds-gray-1", onClick: () => {
                                                                            setEditingPlaybookGoalId(group.id);
                                                                        } }, { children: [_jsx(PencilIcon, { className: "w-4 h-4 relative text-wds-blue-3" }), "Edit tracker configuration"] })), _jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-wds-gray-1", onClick: () => {
                                                                            confirmModal(() => __awaiter(void 0, void 0, void 0, function* () {
                                                                                yield removeGroupFromPlaybook(playbook.id, group.id);
                                                                                refetchCallback();
                                                                            }), `Remove tracker`, `Are you sure you want to remove this tracker from the tracker set?`, "Remove", "Cancel");
                                                                        } }, { children: [_jsx(TrashIcon, { className: "w-4 h-4 relative text-red-600" }), "Remove from this tracker set"] })), _jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-wds-gray-1", onClick: () => {
                                                                            confirmModal(() => __awaiter(void 0, void 0, void 0, function* () {
                                                                                var _a, _b, _c;
                                                                                const response = yield deletePlaybookItemGroup(group.id);
                                                                                const calls = response.calls;
                                                                                const playbooks = response.playbooks.filter((p) => p.id !== playbook.id);
                                                                                const errors = [];
                                                                                if (calls.length > 0) {
                                                                                    if (response.calls.length > 0) {
                                                                                        errors.push(`${calls.length} call(s), like: ${((_a = calls[0]) === null || _a === void 0 ? void 0 : _a.name) || ""} at ${((_b = calls[0]) === null || _b === void 0 ? void 0 : _b.call_time) || ""}`);
                                                                                    }
                                                                                    if (playbooks.length > 0) {
                                                                                        errors.push(`${playbooks.length} tracker(s), like: ${((_c = playbooks[0]) === null || _c === void 0 ? void 0 : _c.name) || ""}`);
                                                                                    }
                                                                                    if (errors.length > 0) {
                                                                                        setPlaybookItemGroupDeleteErrors(`This action will remove this tracker from:\n${errors.join("\n")}`);
                                                                                        setPlaybookItemGroupIdToBeForceDeleted(group.id);
                                                                                    }
                                                                                    else {
                                                                                        refetchCallback();
                                                                                    }
                                                                                }
                                                                            }), "Delete tracker", "Are you sure you want to delete this tracker?", "Delete", "Cancel");
                                                                        } }, { children: [_jsx(TrashIcon, { className: "w-4 h-4 relative text-red-600" }), "Delete tracker"] }))] }) }))] }))] })));
                                    } }), group.id))), placeholder] })));
                    } })) })), editingPlaybookGoalId ? (_jsx(EditGoalModal, { open: !!editingPlaybookGoalId, setOpen: (open) => {
                    if (!open) {
                        setEditingPlaybookGoalId(null);
                    }
                }, goalId: editingPlaybookGoalId !== null && editingPlaybookGoalId !== void 0 ? editingPlaybookGoalId : undefined, playbookId: playbook.id, submitCallback: () => {
                    // the updating is done inside the modal
                    setEditingPlaybookGoalId(null);
                    refetchCallback();
                    fetchAllPlaybookItemGroups();
                } })) : null, creatingPlaybookItemGroup ? (_jsx(EditGoalModal, { open: creatingPlaybookItemGroup, setOpen: setCreatingPlaybookItemGroup, playbookId: playbook.id, submitCallback: (createdPlaybookGroupId) => __awaiter(void 0, void 0, void 0, function* () {
                    yield addGroupToPlaybook(playbook.id, createdPlaybookGroupId);
                    refetchCallback();
                    fetchAllPlaybookItemGroups();
                    setCreatingPlaybookItemGroup(false);
                    setShowAddPlaybookItemGroupModal(false);
                }) })) : null, showAddPlaybookItemGroupModal && !creatingPlaybookItemGroup ? (_jsx(AddPlaybookItemGroupModal, { open: showAddPlaybookItemGroupModal && !creatingPlaybookItemGroup, onClose: () => {
                    setShowAddPlaybookItemGroupModal(false);
                }, addPlaybookItemGroups: (playbookItemGroups) => __awaiter(void 0, void 0, void 0, function* () {
                    yield Promise.all(playbookItemGroups.map((playbookItemGroup) => addGroupToPlaybook(playbook.id, playbookItemGroup.id)));
                    setShowAddPlaybookItemGroupModal(false);
                    refetchCallback();
                }), allPlaybookItemGroups: allPlaybookItemGroups, existingPlaybookItemGroupIds: playbookItemGroups.map((group) => group.id), createCallback: () => {
                    setCreatingPlaybookItemGroup(true);
                } })) : null, playbookItemGroupIdToBeForceDeleted ? (_jsx(BaseModal, Object.assign({ title: "Delete playbook warning", description: "", open: !!playbookItemGroupIdToBeForceDeleted, setOpen: () => setPlaybookItemGroupIdToBeForceDeleted(null), fields: [], submitCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield deletePlaybookItemGroup(playbookItemGroupIdToBeForceDeleted, true);
                    refetchCallback();
                    setPlaybookItemGroupIdToBeForceDeleted(null);
                }), submitText: "Confirm Delete" }, { children: _jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: playbookItemGroupDeleteErrors !== null && playbookItemGroupDeleteErrors !== void 0 ? playbookItemGroupDeleteErrors : "There was an error deleting the playbook..." })) }))) : null] })));
};
export default EditPlaybook;
