import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import BaseModal from "../base_modal";
import SelectionTable from "../selection_table";
import { useNavigate } from "react-router-dom";
const ADD_RESEARCH_BLOCK_LABELS = {
    ACCOUNT: {
        title: "Add account research block",
        description: "A research block represents a single block of information that will be included in the account details.",
        createLabel: "Create a new account research block",
    },
    CALL: {
        title: "Add briefing block",
        description: "A briefing block represents a single block of information that will be included in the pre-call briefing.",
        createLabel: "Create new briefing block",
    },
};
const AddBriefingBlockModal = (props) => {
    const { researchBlockType, open, onClose, onAddBriefingBlocks, allBlocks, existingBlockIds, createCallback, linkToBlock = false, } = props;
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const navigate = useNavigate();
    const applicableResearchBlocks = useMemo(() => {
        return allBlocks.filter((block) => block.research_block_type === researchBlockType);
    }, [allBlocks, researchBlockType]);
    const expandCallback = (block) => {
        navigate("/research", {
            state: { editingBlockId: block.id, templateId: block.template_id },
        });
    };
    return (_jsx(BaseModal, Object.assign({ title: ADD_RESEARCH_BLOCK_LABELS[researchBlockType].title, description: ADD_RESEARCH_BLOCK_LABELS[researchBlockType].description, open: open, setOpen: onClose, fields: [], submitCallback: () => {
            onAddBriefingBlocks(selectedBlocks.map((block) => block.id));
        }, submitText: "Add" }, { children: _jsx(SelectionTable, { columns: [
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "prompt",
                    header: "Prompt",
                    cell: ({ row }) => {
                        if (row.original.prompt.length > 45) {
                            return row.original.prompt.slice(0, 45) + "...";
                        }
                        return row.original.prompt;
                    },
                },
            ], rows: applicableResearchBlocks, setSelectedRows: setSelectedBlocks, canSelectRow: (row) => !existingBlockIds.includes(row.id), createCallback: createCallback, createLabel: ADD_RESEARCH_BLOCK_LABELS[researchBlockType].createLabel, expandCallback: linkToBlock ? expandCallback : undefined }) })));
};
export default AddBriefingBlockModal;
