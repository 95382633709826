import { createInstance, Identify } from "@amplitude/analytics-browser";

const pageViewTrackingEnrichment = () => {
  return {
    name: "page-view-tracking-enrichment",
    type: "enrichment",
    setup: async () => undefined,
    execute: async (event) => {
      if (event.event_type !== "[Amplitude] Page Viewed") {
        return event;
      }
      event.event_properties = {
        ...event.event_properties,
        // Just the first thing after the slash in the url,
        // e.g. /call but not /call/12345
        wiser_page: window.location.pathname.split("/")[1],
      };
      return event;
    },
  };
};

const amplitudeInstance = createInstance();
amplitudeInstance.add(pageViewTrackingEnrichment());

export const setTenantInAmplitude = (tenantId, isStaff, isTenantAdmin) => {
  const identifyEvent = new Identify();
  identifyEvent.set("tenant_id", tenantId);
  identifyEvent.set("is_staff", isStaff);
  identifyEvent.set("is_tenant_admin", isTenantAdmin);
  amplitudeInstance.identify(identifyEvent);
};

export default amplitudeInstance;
