import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingSelectItems } from "../common/loaders";
import { useCallHighlights } from "./use_call_highlights";
import { CallHighlightsHeading } from "./call_highlights_heading";
import { Button } from "../components/button";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
export const CallBrief = ({ call, regeneratingCallHighlights, regenerateCallHighlights, }) => {
    const { callHighlights, callHighlightsLoading } = useCallHighlights(call.id);
    const isFutureCall = new Date(call.call_time) > new Date();
    const renderNoCallSummary = () => {
        return (_jsx("div", Object.assign({ className: "text-wds-gray-5 font-medium pt-2" }, { children: isFutureCall
                ? "Call hasn't started yet."
                : "Not enough transcript to generate a call summary." })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2 self-stretch px-6 py-4 rounded-2xl bg-wds-gray-3 bg-white" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4 self-stretch justify-between items-start" }, { children: [_jsx(CallHighlightsHeading, { title: "Call brief" }), _jsx(Button, Object.assign({ variant: "ghost", className: "flex shrink-0 h-auto p-0 text-wds-gray-5 hover:bg-transparent", onClick: regenerateCallHighlights, disabled: regeneratingCallHighlights }, { children: _jsx(ArrowPathIcon, { className: classNames("h-4 w-4", regeneratingCallHighlights ? "animate-spin" : "") }) }))] })), callHighlightsLoading || regeneratingCallHighlights ? (_jsx(LoadingSelectItems, {})) : (_jsx(_Fragment, { children: (callHighlights === null || callHighlights === void 0 ? void 0 : callHighlights.call_brief) ? (_jsx("div", Object.assign({ className: "whitespace-pre-wrap self-stretch" }, { children: callHighlights === null || callHighlights === void 0 ? void 0 : callHighlights.call_brief }))) : (renderNoCallSummary()) }))] })));
};
