import { useQuery, useQueryClient } from "react-query";
import { getLiveCallSummary } from "../common/endpoints";
export const useCallHighlights = (callId) => {
    const { data: callHighlights, isLoading: callHighlightsLoading } = useQuery({
        queryKey: ["callHighlights", callId],
        queryFn: () => getLiveCallSummary(callId),
    });
    const queryClient = useQueryClient();
    const invalidateCallHighlights = () => {
        queryClient.invalidateQueries(["callHighlights", callId]);
    };
    return {
        callHighlights,
        callHighlightsLoading,
        invalidateCallHighlights,
    };
};
