import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { LoadingEllipsis } from "../common/loading_ellipsis";
export const RunningSummary = (props) => {
    const renderEmptySummary = () => {
        return (_jsx("div", Object.assign({ className: "p-2" }, { children: _jsx("span", Object.assign({ className: "font-normal text-sm text-black" }, { children: "No summary available" })) })));
    };
    const renderSummary = (summary) => {
        return (_jsx("div", Object.assign({ className: "flex flex-row items-start justify-between gap-2" }, { children: _jsxs("div", Object.assign({ className: "p-2 bg-gray-100 rounded-lg flex flex-col gap-2 whitespace-pre-wrap w-full" }, { children: [summary.id === 0 && _jsx(LoadingEllipsis, {}), _jsx("div", { className: "font-normal text-sm text-black", dangerouslySetInnerHTML: { __html: summary.summary_html.trim() } })] })) }), summary.id));
    };
    if (props.summaries.length === 0) {
        return renderEmptySummary();
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 pt-2 px-6 pb-6 bg-white w-full h-fit" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-lg" }, { children: "Key points" })), _jsxs("div", Object.assign({ className: "flex items-center gap-1" }, { children: [_jsx("span", Object.assign({ className: "bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" }, { children: "AI generated" })), _jsx(InformationCircleIcon, { className: "h-4 w-4 text-pink-500" })] }))] })), _jsx("div", Object.assign({ className: "flex flex-col gap-4 h-full" }, { children: props.summaries.map((summary) => renderSummary(summary)) }))] })));
};
