var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisVerticalIcon, PencilIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { addPreCallPrepBlockToTemplate, getAllPreCallPrepBlocks, getPreCallPrepBlocksForTemplate, getPreCallPrepTemplate, removePreCallPrepBlockFromTemplate, reorderPreCallPrepBlocks, updatePreCallPrepTemplate, } from "./common/endpoints";
import { Popover } from "@headlessui/react";
import { confirmModal } from "./common/confirmation_modal";
import AddBriefingBlockModal from "./pre_call_prep/add_briefing_block_modal";
import CreateOrEditBriefingModal from "./pre_call_prep/create_or_edit_briefing_modal";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
const EditPreCallTemplate = (props) => {
    var _a, _b, _c, _d;
    const { templateId, refetch } = props;
    const location = useLocation();
    const [name, setName] = useState(null);
    const [blocks, setBlocks] = useState([]);
    const [allBlocks, setAllBlocks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editingBlockId, setEditingBlockId] = useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.editingBlockId) !== null && _b !== void 0 ? _b : null);
    const [showAddBlockModal, setShowAddBlockModal] = useState(false);
    const [creatingBlock, setCreatingBlock] = useState((_d = (_c = location.state) === null || _c === void 0 ? void 0 : _c.creatingBlock) !== null && _d !== void 0 ? _d : false);
    useEffect(() => {
        fetchTemplate();
        fetchBlocks();
    }, [templateId]);
    useEffect(() => {
        fetchAllBlocks();
    }, []);
    const fetchAllBlocks = () => __awaiter(void 0, void 0, void 0, function* () {
        const blocks = yield getAllPreCallPrepBlocks();
        setAllBlocks(blocks);
    });
    const updateTemplateName = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!name)
            return; // todo show validation error
        yield updatePreCallPrepTemplate(templateId, name);
        yield refetch();
    });
    const fetchBlocks = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getPreCallPrepBlocksForTemplate(templateId);
        setBlocks(response);
    });
    const fetchTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getPreCallPrepTemplate(templateId);
        setName(response.name);
    });
    const removeBlock = (blockId) => __awaiter(void 0, void 0, void 0, function* () {
        yield removePreCallPrepBlockFromTemplate(templateId, blockId);
        yield fetchBlocks();
        yield refetch();
    });
    const onDragEnd = (result) => __awaiter(void 0, void 0, void 0, function* () {
        if (!result.destination)
            return;
        const reOrderedBlocks = [...blocks];
        const [removedBlock] = reOrderedBlocks.splice(result.source.index, 1);
        reOrderedBlocks.splice(result.destination.index, 0, removedBlock);
        setBlocks(reOrderedBlocks);
        yield reorderPreCallPrepBlocks(templateId, reOrderedBlocks.map((block) => block.id));
    });
    return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pb-4 border-b border-wds-gray-3 flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Details" })), !isEditing ? (_jsxs("button", Object.assign({ className: "px-3 py-1 rounded-full justify-start items-center gap-2 flex hover:bg-wds-blue-1", onClick: () => {
                                    setIsEditing(true);
                                } }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Edit" })), _jsx(PencilIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))) : null] })), _jsxs("div", Object.assign({ className: "w-full flex-col justify-start items-start gap-1 flex" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 text-xs font-medium leading-none" }, { children: "Name" })), isEditing ? (_jsx("input", { type: "text", value: name !== null && name !== void 0 ? name : "", onChange: (e) => setName(e.target.value), className: "w-full h-9 px-1 bg-white shadow border-t rounded-lg border-wds-gray-3 justify-start items-center gap-1 inline-flex", onBlur: () => {
                                    updateTemplateName();
                                    setIsEditing(false);
                                }, autoFocus: true })) : (_jsx("div", Object.assign({ className: "text-black leading-snug" }, { children: name })))] }))] })), _jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Research Blocks" })), _jsxs("button", Object.assign({ className: "px-3 py-1 rounded-full justify-start items-center gap-2 flex hover:bg-wds-blue-1", onClick: () => {
                            setShowAddBlockModal(true);
                        } }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Add" })), _jsx(PlusIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))] })), _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "research-blocks" }, { children: ({ droppableProps, innerRef, placeholder }) => {
                        return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-2 flex" }, droppableProps, { ref: innerRef }, { children: [blocks.map((block, index) => (_jsx(Draggable, Object.assign({ draggableId: block.id.toString(), index: index }, { children: ({ draggableProps, dragHandleProps, innerRef }) => {
                                        return (_jsxs("div", Object.assign({ className: "relative self-stretch pl-4 pr-2 py-4 bg-white rounded-lg border border-neutral-200 justify-end items-start gap-2 inline-flex" }, draggableProps, dragHandleProps, { ref: innerRef }, { children: [_jsx("div", Object.assign({ className: "grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex" }, { children: _jsx("div", Object.assign({ className: "self-stretch text-neutral-950 font-medium leading-tight" }, { children: block.name })) })), _jsxs(Popover, Object.assign({ as: "div" }, { children: [_jsx(Popover.Button, Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-wds-blue-1" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-4 h-4" }) })), _jsx(Popover.Panel, Object.assign({ className: "absolute right-[40px] z-10 w-46 py-2 bg-white rounded-lg shadow border border-neutral-200 flex-col inline-flex" }, { children: _jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-wds-gray-1", onClick: () => {
                                                                            setEditingBlockId(block.id);
                                                                        } }, { children: [_jsx(PencilIcon, { className: "w-4 h-4 relative text-wds-blue-3" }), "Edit block configuration"] })), _jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-wds-gray-1", onClick: () => {
                                                                            confirmModal(() => removeBlock(block.id), `Remove block ${block.name}`, `Are you sure you want to remove this block from the template?`, "Remove", "Cancel");
                                                                        } }, { children: [_jsx(TrashIcon, { className: "w-4 h-4 relative text-red-600" }), "Remove from this template"] }))] }) }))] }))] })));
                                    } }), block.id))), placeholder] })));
                    } })) })), creatingBlock && (_jsx(CreateOrEditBriefingModal, { templateId: templateId, open: creatingBlock, onClose: () => setCreatingBlock(false), refetch: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield fetchBlocks();
                    yield refetch();
                    yield fetchAllBlocks();
                    setCreatingBlock(false);
                    setShowAddBlockModal(false);
                }), callPrepBlock: null })), showAddBlockModal && !creatingBlock && (_jsx(AddBriefingBlockModal, { researchBlockType: "CALL", onClose: () => setShowAddBlockModal(false), onAddBriefingBlocks: (blockIds) => __awaiter(void 0, void 0, void 0, function* () {
                    yield Promise.all(blockIds.map((blockId) => addPreCallPrepBlockToTemplate(templateId, blockId)));
                    yield fetchBlocks();
                    yield refetch();
                    setShowAddBlockModal(false);
                }), allBlocks: allBlocks, existingBlockIds: blocks.map((block) => block.id), open: showAddBlockModal && !creatingBlock, createCallback: () => setCreatingBlock(true) })), editingBlockId && blocks.find((b) => b.id === editingBlockId) ? (_jsx(CreateOrEditBriefingModal, { templateId: templateId, open: editingBlockId !== null, onClose: () => setEditingBlockId(null), refetch: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield fetchBlocks();
                    yield refetch();
                    yield fetchAllBlocks();
                }), callPrepBlock: blocks.find((block) => block.id === editingBlockId) })) : null] })));
};
export default EditPreCallTemplate;
