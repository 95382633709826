import React from "react";
/**
 * Applies deboucing on any thing, not restricted to a function.
 * This is a very react way of doing debounce and is more generic.
 *
 * @param value The thing to debounce.
 * @param delay The number of milliseconds to delay.
 * @returns The new debounced function.
 */
export function debounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    React.useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay !== null && delay !== void 0 ? delay : 500);
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
}
