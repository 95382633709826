import { MeetingType } from "./video_playback_status_utils";
export const IMAGE_SRCS = new Map([
    [
        MeetingType.GoogleMeet,
        "https://storage.googleapis.com/wiser-ai-public2/logo_meet_2020q4_color_2x_web_96dp.png",
    ],
    [
        MeetingType.Zoom,
        "https://storage.googleapis.com/wiser-ai-public2/zoom-meetings-rgb.png",
    ],
    [
        MeetingType.LocalAudio,
        "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png",
    ],
    [
        MeetingType.Gong,
        "https://storage.googleapis.com/wiser-ai-public2/gong_logo.ico",
    ],
]);
export const MEETING_TEXTS = new Map([
    [MeetingType.GoogleMeet, "Google Meet"],
    [MeetingType.Zoom, "Zoom"],
    [MeetingType.LocalAudio, "Local Audio"],
    [MeetingType.Gong, "Uploaded from Gong"],
]);
