import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { dataTableConfig } from "../table_helpers";
import { Button } from "../components/button";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, } from "../components/select";
import { DataTableAdvancedFacetedFilter } from "./data_table_advanced_faceted_filter";
import { debounce } from "../common/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "../class_names";
import { TrashIcon } from "@heroicons/react/24/outline";
/**
 * This is the main advanced filter this allows user to select any column
 * and apply filters to it. This changes the query params in the URL
 * which can then be used to filter the data.
 */
export function DataTableFilterItem({ table, selectedOption, selectedOptions, setSelectedOptions, defaultOpen, }) {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const column = table.getColumn(selectedOption.value ? String(selectedOption.value) : "");
    const selectedValues = new Set((_a = selectedOptions.find((item) => item.value === (column === null || column === void 0 ? void 0 : column.id))) === null || _a === void 0 ? void 0 : _a.filterValues);
    const filterValues = Array.from(selectedValues);
    const filterOperator = (_b = selectedOptions.find((item) => item.value === (column === null || column === void 0 ? void 0 : column.id))) === null || _b === void 0 ? void 0 : _b.filterOperator;
    const operators = selectedOption.options.length > 0
        ? dataTableConfig.selectableOperators
        : dataTableConfig.comparisonOperators;
    const [value, setValue] = React.useState((_c = filterValues[0]) !== null && _c !== void 0 ? _c : "");
    const debounceValue = debounce(value, 300);
    const [open, setOpen] = React.useState(defaultOpen);
    const [selectedOperator, setSelectedOperator] = React.useState((_d = operators.find((c) => c.value === filterOperator)) !== null && _d !== void 0 ? _d : operators[0]);
    // Create query string
    const createQueryString = React.useCallback((params) => {
        const newSearchParams = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
        for (const [key, value] of Object.entries(params)) {
            if (value === null) {
                newSearchParams.delete(key);
            }
            else {
                newSearchParams.set(key, String(value));
            }
        }
        return newSearchParams.toString();
    }, [searchParams]);
    // Update query string
    React.useEffect(() => {
        if (selectedOption.options.length > 0) {
            // key=value1.value2.value3~operator
            const newSearchParams = createQueryString({
                [String(selectedOption.value)]: filterValues.length > 0
                    ? `${filterValues.join(".")}~${selectedOperator === null || selectedOperator === void 0 ? void 0 : selectedOperator.value}`
                    : null,
            });
            navigate(`?${newSearchParams}`);
        }
        else {
            // key=value~operator
            const newSearchParams = createQueryString({
                [String(selectedOption.value)]: debounceValue.length > 0
                    ? `${debounceValue}~${selectedOperator === null || selectedOperator === void 0 ? void 0 : selectedOperator.value}`
                    : null,
            });
            navigate(`?${newSearchParams}`);
        }
    }, [selectedOption, debounceValue, selectedOperator]);
    return (_jsxs(Popover, Object.assign({ open: open, onOpenChange: setOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", className: classNames("h-7 gap-0 truncate rounded-full text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6", (selectedValues.size > 0 || value.length > 0) && "bg-muted-dark") }, { children: [_jsx("span", Object.assign({ className: "font-medium capitalize" }, { children: selectedOption.label })), selectedOption.options.length > 0
                            ? selectedValues.size > 0 && (_jsxs("span", Object.assign({ className: "text-muted-foreground" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-6" }, { children: ": " })), selectedValues.size > 2
                                        ? `${selectedValues.size} selected`
                                        : selectedOption.options
                                            .filter((item) => selectedValues.has(item.value))
                                            .map((item) => item.label)
                                            .join(", ")] })))
                            : value.length > 0 && (_jsxs("span", Object.assign({ className: "text-muted-foreground" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-6" }, { children: ": " })), value] })))] })) })), _jsxs(PopoverContent, Object.assign({ className: "w-60 space-y-1.5 p-2", align: "start" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-1 pl-1 pr-0.5" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-1 items-center space-x-1" }, { children: [_jsx("div", Object.assign({ className: "text-xs capitalize text-muted-foreground" }, { children: selectedOption.label })), _jsxs(Select, Object.assign({ value: selectedOperator === null || selectedOperator === void 0 ? void 0 : selectedOperator.value, onValueChange: (value) => 
                                        // @ts-ignore
                                        setSelectedOperator(operators.find((c) => c.value === value)) }, { children: [_jsx(SelectTrigger, Object.assign({ className: "h-auto w-fit truncate border-none px-2 py-0.5 text-xs hover:bg-muted-dark" }, { children: _jsx(SelectValue, { placeholder: selectedOperator === null || selectedOperator === void 0 ? void 0 : selectedOperator.label }) })), _jsx(SelectContent, { children: _jsx(SelectGroup, { children: operators.map((item) => (_jsx(SelectItem, Object.assign({ value: item.value, className: "py-1" }, { children: item.label }), item.value))) }) })] }))] })), _jsx(Button, Object.assign({ "aria-label": "Remove filter", variant: "ghost", size: "icon", className: "h-7 w-7 text-muted-foreground", onClick: () => {
                                    setSelectedOptions((prev) => prev.filter((item) => item.value !== selectedOption.value));
                                    const newSearchParams = createQueryString({
                                        [String(selectedOption.value)]: null,
                                    });
                                    navigate(`?${newSearchParams}`);
                                } }, { children: _jsx(TrashIcon, { className: "h-4 w-4", "aria-hidden": "true" }) }))] })), selectedOption.options.length > 0 ? (column && (_jsx(DataTableAdvancedFacetedFilter, { column: column, title: selectedOption.label, options: selectedOption.options, selectedValues: selectedValues, setSelectedOptions: setSelectedOptions }, String(selectedOption.value)))) : (_jsx("input", { placeholder: "Type here...", className: "h-8", value: value, onChange: (event) => setValue(event.target.value), autoFocus: true }))] }))] })));
}
