"use client";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { addDays, format } from "date-fns";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
/**
 * A pre-styled date picker utilising the calendar and popover component. Refer to the props
 * for more information on how to use.
 * This component will change the query params in the URL on date range picking.
 *
 * This differs from the hope page date picker, in that it does not require you send a date range into the component
 * It defaults to the current month + previous month.
 * This component is also quite larger in size with more consistent styling similar to colors in the design system figma.
 */
export function DateRangePicker(_a) {
    var { dateRange, dayCount, placeholder = "Pick a date", triggerVariant = "outline", triggerSize = "default", triggerClassName, className } = _a, props = __rest(_a, ["dateRange", "dayCount", "placeholder", "triggerVariant", "triggerSize", "triggerClassName", "className"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [date, setDate] = React.useState(() => {
        const fromParam = searchParams.get("from");
        const toParam = searchParams.get("to");
        let fromDay;
        let toDay;
        if (dateRange) {
            fromDay = dateRange.from;
            toDay = dateRange.to;
        }
        else if (dayCount) {
            toDay = new Date();
            fromDay = addDays(toDay, -dayCount);
        }
        return {
            from: fromParam ? new Date(fromParam) : fromDay,
            to: toParam ? new Date(toParam) : toDay,
        };
    });
    React.useEffect(() => {
        if (props.resetFlag && props.setResetFlag) {
            setDate({ from: undefined, to: undefined });
            props.setResetFlag(false);
        }
    }, [props.resetFlag]);
    // Update query string
    React.useEffect(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (date === null || date === void 0 ? void 0 : date.from) {
            newSearchParams.set("from", format(date.from, "yyyy-MM-dd"));
        }
        else {
            newSearchParams.delete("from");
        }
        if (date === null || date === void 0 ? void 0 : date.to) {
            newSearchParams.set("to", format(date.to, "yyyy-MM-dd"));
        }
        else {
            newSearchParams.delete("to");
        }
        navigate(`?${newSearchParams.toString()}`);
    }, [date === null || date === void 0 ? void 0 : date.from, date === null || date === void 0 ? void 0 : date.to]);
    return (_jsx("div", Object.assign({ className: "grid gap-2" }, { children: _jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: triggerVariant, size: triggerSize, className: classNames("w-full justify-start truncate text-left font-normal text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6", triggerClassName) }, { children: [_jsx(CalendarDaysIcon, { className: "h-4 w-4 mr-2" }), (date === null || date === void 0 ? void 0 : date.from) ? (date.to ? (_jsxs(_Fragment, { children: [format(date.from, "LLL dd, y"), " -", " ", format(date.to, "LLL dd, y")] })) : (format(date.from, "LLL dd, y"))) : (_jsx("span", { children: placeholder }))] })) })), _jsx(PopoverContent, Object.assign({ className: classNames("w-auto p-0", className) }, props, { children: _jsx(Calendar, { initialFocus: true, mode: "range", defaultMonth: date === null || date === void 0 ? void 0 : date.from, selected: date, onSelect: setDate, numberOfMonths: 2 }) }))] }) })));
}
