var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "./table";
import { DataTablePagination } from "./data_table_pagination";
import classNames from "../class_names";
import { getCommonPinningStyles } from "../table_helpers";
export function DataTable(_a) {
    var _b;
    var { table, floatingBar = null, children, className } = _a, props = __rest(_a, ["table", "floatingBar", "children", "className"]);
    return (_jsxs("div", Object.assign({ className: classNames("w-full space-y-2.5 overflow-auto", className) }, props, { children: [children, _jsx("div", Object.assign({ className: "overflow-auto rounded-md border h-[73vh] relative" }, { children: _jsxs(Table, { children: [_jsx(TableHeader, Object.assign({ className: "sticky top-0 z-10" }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, { children: headerGroup.headers.map((header) => {
                                    return (_jsx(TableHead, Object.assign({ colSpan: header.colSpan, style: Object.assign({}, getCommonPinningStyles({ column: header.column })) }, { children: header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext()) }), header.id));
                                }) }, headerGroup.id))) })), _jsx(TableBody, { children: ((_b = table.getRowModel().rows) === null || _b === void 0 ? void 0 : _b.length) ? (table.getRowModel().rows.map((row) => (_jsx(TableRow, Object.assign({ "data-state": row.getIsSelected() && "selected" }, { children: row.getVisibleCells().map((cell) => (_jsx(TableCell, Object.assign({ style: Object.assign({}, getCommonPinningStyles({ column: cell.column })) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))) }), row.id)))) : (_jsx(TableRow, { children: _jsx(TableCell, Object.assign({ colSpan: table.getAllColumns().length, className: "h-24 text-center" }, { children: "No results." })) })) })] }) })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2.5" }, { children: [_jsx(DataTablePagination, { table: table }), table.getFilteredSelectedRowModel().rows.length > 0 && floatingBar] }))] })));
}
