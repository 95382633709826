import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "./button";
import { CheckIcon, ViewColumnsIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Separator } from "./separator";
import { Badge } from "./badge";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "./command";
import classNames from "../class_names";
/**
 * Updated component using the Command component. This allows for searching and filtering.
 *  * @example
 * ```
 * <DataTableViewOptions table={table} />
 * ```
 */
export function DataTableViewOptions({ table, columnIdMap, }) {
    const visibleColsArray = table
        .getAllColumns()
        .filter((column) => column.getIsVisible());
    const visibleColumns = new Set(visibleColsArray);
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", className: "h-8 text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: [_jsx(ViewColumnsIcon, { className: "mr-2 h-4 w-4" }), "View", _jsxs(_Fragment, { children: [_jsx(Separator, { orientation: "vertical", className: "mx-2 h-4" }), _jsx(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal lg:hidden" }, { children: visibleColsArray.length })), _jsx("div", Object.assign({ className: "hidden space-x-1 lg:flex" }, { children: visibleColsArray.length > 2 ? (_jsxs(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal" }, { children: [visibleColsArray.length, " selected"] }))) : (visibleColsArray.map((column) => (_jsx(Badge, Object.assign({ variant: "secondary", className: "rounded-sm px-1 font-normal capitalize" }, { children: column.id }), column.id)))) }))] })] })) })), _jsx(PopoverContent, Object.assign({ className: "w-[12.5rem] p-0", align: "start" }, { children: _jsxs(Command, { children: [_jsx(CommandInput, { placeholder: "Filter" }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsx(CommandGroup, { children: table.getAllColumns().map((column) => {
                                        var _a;
                                        const isSelected = visibleColumns.has(column);
                                        return (_jsxs(CommandItem, Object.assign({ onSelect: () => {
                                                if (isSelected) {
                                                    visibleColumns.delete(column);
                                                }
                                                else {
                                                    visibleColumns.add(column);
                                                }
                                                column.toggleVisibility(!isSelected);
                                            } }, { children: [_jsx("div", Object.assign({ className: classNames("mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary", isSelected
                                                        ? "bg-primary text-primary-foreground"
                                                        : "opacity-50 [&_svg]:invisible") }, { children: _jsx(CheckIcon, { className: "h-4 w-4", "aria-hidden": "true" }) })), _jsx("span", { children: typeof column.columnDef.header === "string"
                                                        ? column.columnDef.header
                                                        : (_a = columnIdMap === null || columnIdMap === void 0 ? void 0 : columnIdMap[column.id]) !== null && _a !== void 0 ? _a : column.id })] }), column.id));
                                    }) })] })] }) }))] }));
}
