import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button } from "../components/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, } from "../components/command";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { ChevronDownIcon, FunnelIcon, MagnifyingGlassIcon, PlusIcon, } from "@heroicons/react/24/outline";
/**
 * Data table combobox to be used when advanced filters are enabled
 */
export function DataTableFilterCombobox({ options, selectedOptions, setSelectedOptions, onSelect, children, }) {
    var _a;
    const [value, setValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState((_a = options[0]) !== null && _a !== void 0 ? _a : {});
    return (_jsxs(Popover, Object.assign({ open: open, onOpenChange: setOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: children !== null && children !== void 0 ? children : (_jsxs(Button, Object.assign({ variant: "outline", size: "sm", role: "combobox", className: "capitalize text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: [_jsx(FunnelIcon, { className: "h-4 w-4 mr-2 shrink-0", "aria-hidden": "true" }), "Filter"] }))) })), _jsx(PopoverContent, Object.assign({ className: "w-[12.5rem] p-0", align: "end" }, { children: _jsxs(Command, { children: [_jsx(CommandInput, { placeholder: "Filter by..." }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No item found." }), _jsx(CommandGroup, { children: options
                                        .filter((option) => !selectedOptions.some((selectedOption) => selectedOption.value === option.value))
                                        .map((option) => (_jsxs(CommandItem, Object.assign({ className: "capitalize", value: String(option.value), onSelect: (currentValue) => {
                                            setValue(currentValue === value ? "" : currentValue);
                                            setOpen(false);
                                            setSelectedOption(option);
                                            setSelectedOptions((prev) => {
                                                return [...prev, Object.assign({}, option)];
                                            });
                                            onSelect();
                                        } }, { children: [option.options.length > 0 ? (_jsx(ChevronDownIcon, { className: "h-4 w-4 mr-2", "aria-hidden": "true" })) : (_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 mr-2", "aria-hidden": "true" })), option.label] }), String(option.value)))) }), _jsx(CommandSeparator, {}), _jsx(CommandGroup, { children: _jsxs(CommandItem, Object.assign({ onSelect: () => {
                                            var _a, _b, _c;
                                            setOpen(false);
                                            setSelectedOptions([
                                                ...selectedOptions,
                                                {
                                                    id: crypto.randomUUID(),
                                                    label: (_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) !== null && _a !== void 0 ? _a : "",
                                                    value: (_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) !== null && _b !== void 0 ? _b : "",
                                                    options: (_c = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.options) !== null && _c !== void 0 ? _c : [],
                                                    isMulti: true,
                                                },
                                            ]);
                                            onSelect();
                                        } }, { children: [_jsx(PlusIcon, { className: "h-4 w-4 mr-2", "aria-hidden": "true" }), "Advanced filter"] })) })] })] }) }))] })));
}
