import { useState, useEffect } from "react";
import useParagonAuth from "../use_paragon_auth";
import { INTEGRATION_DETAILS } from "./data_processing_config";
import { isTenantConnectedToExternalCrm } from "./utils";
/**
 * Fetches & returns the data needed to load all the integrations for post-call ops page.
 * This hook allows us to fetch integrations from any source like paragon, and format them
 * in the psot-call data processing compatible format.
 */
const useIntegrations = () => {
    const [integrations, setIntegrations] = useState([]);
    const { user, paragon, paragonUserLoaded } = useParagonAuth();
    const isParagonIntegrationConnected = (integration) => {
        var _a;
        if (!user || !user.authenticated) {
            return false;
        }
        const { enabled, credentialStatus } = ((_a = user === null || user === void 0 ? void 0 : user.integrations) === null || _a === void 0 ? void 0 : _a[integration.type]) || {};
        if (!enabled) {
            return false;
        }
        if (credentialStatus !== "VALID") {
            return false;
        }
        return true;
    };
    useEffect(() => {
        if (!user || !user.authenticated || !paragonUserLoaded) {
            return;
        }
        const paragonIntegrations = paragon === null || paragon === void 0 ? void 0 : paragon.getIntegrationMetadata();
        const formattedIntegrations = [
            ...(paragonIntegrations
                ? paragonIntegrations
                    .filter((integration) => isParagonIntegrationConnected(integration))
                    .filter((integration) => INTEGRATION_DETAILS.has(integration.name))
                    .map((integration) => {
                    var _a, _b;
                    return ({
                        name: INTEGRATION_DETAILS.get(integration.name).name,
                        label: integration.name,
                        icon_url: INTEGRATION_DETAILS.get(integration.name).icon_url,
                        metadata: (_b = ((_a = user === null || user === void 0 ? void 0 : user.integrations) === null || _a === void 0 ? void 0 : _a[integration.type]).providerData) !== null && _b !== void 0 ? _b : {},
                    });
                })
                : []),
        ];
        if (!isTenantConnectedToExternalCrm(formattedIntegrations)) {
            formattedIntegrations.push({
                name: INTEGRATION_DETAILS.get("Wiser CRM").name,
                label: "Wiser CRM",
                icon_url: INTEGRATION_DETAILS.get("Wiser CRM").icon_url,
                metadata: {},
            });
        }
        setIntegrations(formattedIntegrations);
    }, [user, paragon, paragonUserLoaded]);
    return {
        integrations,
        loaded: paragonUserLoaded,
    };
};
export default useIntegrations;
