var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
import { createColumnHelper } from "@tanstack/react-table";
import CrmFieldValue from "./crm_field_value";
import { IndeterminateCheckbox } from "./select_checkbox";
import { CrmTable } from "./crm_table";
import { Button } from "../components/button";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { Link } from "react-router-dom";
const CrmAccounts = () => {
    // State to store the CRM accounts keyed by their ID.
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const accountResearchPageEnabled = useFeatureFlagEnabled("ACCOUNT_RESEARCH_PAGE");
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("id", {
                id: "row-select",
                header: (props) => (_jsx("div", Object.assign({ className: "flex items-center justify-center p-4" }, { children: _jsx(IndeterminateCheckbox, Object.assign({}, {
                        checked: props.table.getIsAllRowsSelected(),
                        indeterminate: props.table.getIsSomeRowsSelected(),
                        onChange: props.table.getToggleAllRowsSelectedHandler(),
                    })) }))),
                cell: (props) => (_jsxs("div", Object.assign({ className: "flex gap-1 items-center justify-center p-1 shadow-2xl" }, { children: [_jsx(IndeterminateCheckbox, Object.assign({}, {
                            checked: props.row.getIsSelected(),
                            disabled: !props.row.getCanSelect(),
                            indeterminate: props.row.getIsSomeSelected(),
                            onChange: props.row.getToggleSelectedHandler(),
                        })), accountResearchPageEnabled && (_jsx(Link, Object.assign({ to: `/account/${props.row.id}` }, { children: _jsxs(Button, Object.assign({ variant: "ghost", className: "group flex h-8 w-8 hover:bg-wds-gray-2 rounded-full hover:text-wds-blue-3" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/see_details_icon_gray.png", className: "group-hover:hidden max-w-fit w-5 h-5 shrink-0" }), _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/see_details_icon_blue.png", className: "hidden group-hover:block max-w-fit w-5 h-5 shrink-0" })] })) })))] }))),
                size: 60,
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.account_name) !== null && _a !== void 0 ? _a : row.name; }, {
                id: "account_name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        setCrmAccountsMap((prevCrmAccountsMap) => {
                            const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                            if (updatedCrmAccount) {
                                updatedCrmAccount.account_name = newValue;
                                return new Map([
                                    ...prevCrmAccountsMap,
                                    [parseInt(props.row.id), updatedCrmAccount],
                                ]);
                            }
                            return prevCrmAccountsMap;
                        });
                        yield endpoints.updateCrmAccount(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* website= */ undefined, 
                        /* linkedin_profile_url= */ undefined);
                    }) })),
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor("website", {
                id: "website",
                header: "Website",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -2, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.website = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield endpoints.updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ newValue, 
                            /* linkedin_profile_url= */ undefined);
                        }) }));
                },
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 400,
            }),
            columnHelper.accessor("linkedin_profile_url", {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -3, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.linkedin_profile_url = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield endpoints.updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ undefined, 
                            /* linkedin_profile_url= */ newValue);
                        }) }));
                },
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 400,
            }),
        ];
    }, [accountResearchPageEnabled]);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmAccountsMap.values());
    }, [crmAccountsMap]);
    const fetchCrmAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield endpoints.getCrmAccounts();
        setCrmAccountsMap(new Map(response.map((account) => [account.id, account])));
    });
    useEffect(() => {
        fetchCrmAccounts();
    }, []);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmAccount = yield endpoints.createCrmAccount(newRowData.find((rowData) => rowData.columnId === "account_name").value, newRowData.find((rowData) => rowData.columnId === "website").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, crmFieldValues);
        const updatedCrmAccount = yield endpoints.getCrmAccount(newCrmAccount.id);
        setCrmAccountsMap((prevCrmAccountsMap) => {
            return new Map([
                ...prevCrmAccountsMap,
                [updatedCrmAccount.id, updatedCrmAccount],
            ]);
        });
    });
    const onCrmFieldValueChange = (accountId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield endpoints.createOrUpdateCrmAccountData(accountId, crmField.id, newValue);
        setCrmAccountsMap((prevMap) => {
            const updatedAccount = prevMap.get(accountId);
            if (updatedAccount) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedAccount.crm_data.some((crmData) => crmData.crm_field.id === crmField.id)) {
                    updatedAccount.crm_data = updatedAccount.crm_data.map((crmData) => crmData.crm_field.id === crmField.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedAccount.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: crmField,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [accountId, updatedAccount]]);
            }
            return prevMap;
        });
    });
    return (_jsx(CrmTable, { tableData: {
            crmObject: "Account",
            rows: tableData,
        }, columns: columns, columnData: [], onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, refetchCrmData: fetchCrmAccounts }));
};
export default CrmAccounts;
