import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useEffect, useState, } from "react";
import "./editor.css";
import { NoteLoaderFrame } from "../common/loaders";
import amplitudeInstance from "../amplitude";
export default function ManualNotes({ enabled, roleSwitcherRef, manualNote, onManualNoteChange, isMosaicScrolling, setManualNote, }) {
    const [note, setNote] = useState(manualNote);
    const noteRef = useRef(note);
    useEffect(() => {
        if (manualNote != null)
            setNote(manualNote);
        noteRef.current = manualNote;
    }, [manualNote]);
    // Persist manual notes everytime the editor changes
    const handleEditorChange = (content, editor) => {
        if (!enabled)
            return;
        if (content !== manualNote) {
            // Only send the change if it's different from our input.
            // This avoids update loops in multiplayer.
            onManualNoteChange(content);
            amplitudeInstance.track("Manual Note Change", {
                noteLength: content.length,
            });
        }
        setNote(content);
        noteRef.current = content;
    };
    useEffect(() => {
        // On component unmount set the notes in the parent component
        // This ensures that when the component re-mounts
        // It has the latest "correct" notes
        // These cannot be managed in a state variable since changes to state
        // cause re-render which moves the cursor to the start of the editor
        return () => {
            if (setManualNote) {
                setManualNote(noteRef.current);
            }
        };
    }, []);
    if (!enabled) {
        return _jsx(NoteLoaderFrame, {});
    }
    return (_jsxs("div", Object.assign({ className: "h-full" }, { children: [_jsx(Editor, { apiKey: "d5k8z9kycjmnjh6rduiv20644jg43k5r0yb8kpux0pae0xuf", init: {
                    keep_styles: false,
                    plugins: [
                        "powerpaste",
                        "advcode",
                        "casechange",
                        "formatpainter",
                        "linkchecker",
                        "autolink",
                        "lists",
                        "checklist",
                        "media",
                        "mediaembed",
                        "pageembed",
                        "permanentpen",
                        "table",
                        "advtable",
                        "image",
                        "link",
                        "table",
                        "lists",
                        "nonbreaking",
                    ],
                    toolbar: "fontfamily | fontsize | blocks | forecolor backcolor | bold italic underline | numlist bullist | image insertfile link  | code",
                    menubar: false,
                    height: "100%",
                    branding: false,
                    statusbar: false,
                    // Setting single space inside the editor
                    forced_root_block: "div",
                    // Styles for the edit area
                    content_style: "body { background-color:#FFFFFF; padding: 3% 3% 0%;}",
                    skin: "snow",
                    powerpaste_allow_local_images: true,
                    powerpaste_word_import: "merge",
                    powerpaste_html_import: "merge",
                    powerpaste_googledocs_import: "merge",
                    nonbreaking_force_tab: true,
                    paste_as_text: false,
                    // Short cut keys
                    textpattern_patterns: [
                        { start: "1. ", cmd: "InsertOrderedList" },
                        { start: "* ", cmd: "InsertUnorderedList" },
                        { start: " * ", cmd: "InsertUnorderedList" },
                        { start: "- ", cmd: "InsertUnorderedList" },
                        { start: " - ", cmd: "InsertUnorderedList" },
                    ],
                    setup: function (editor) {
                        editor.on("click", function (event) {
                            var _a, _b;
                            // TODO: This to handle click event from role picker modal to dismiss it, seems a little hacky but can't find a solution yet
                            // Throw up a  throw up an invisible full-page div behind the modal but on top of everything else and let that get any click events
                            if (((_a = roleSwitcherRef === null || roleSwitcherRef === void 0 ? void 0 : roleSwitcherRef.current) === null || _a === void 0 ? void 0 : _a.getAttribute("data-headlessui-state")) === "open") {
                                (_b = roleSwitcherRef === null || roleSwitcherRef === void 0 ? void 0 : roleSwitcherRef.current) === null || _b === void 0 ? void 0 : _b.click();
                            }
                        });
                        // Sets Arial as default font for the font family toolbar
                        editor.on("init", function () {
                            editor.execCommand("fontName", false, "Arial");
                            editor.getBody().style.fontFamily = "Arial";
                            // Stops propagation of the scroll event coming from Mosaic during resize of the tile
                            let iframe = editor.getWin();
                            iframe.addEventListener("scroll", function (event) {
                                event.stopPropagation();
                            }, true);
                        });
                    },
                }, onEditorChange: handleEditorChange, initialValue: manualNote, value: note }, "manual-notes"), isMosaicScrolling && (_jsx("div", { className: "transparentDiv", style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    zIndex: 9999,
                } }))] })));
}
