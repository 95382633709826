import { CodeBracketSquareIcon, } from "@heroicons/react/24/outline";
/**
 * A utility function to get classNames based on the column's properties.
 * This helps to easily style each column in the table based on its properties.
 */
export function getCommonPinningStyles({ column, }) {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");
    return {
        boxShadow: isLastLeftPinnedColumn
            ? "-5px 0 5px -5px hsl(var(--border)) inset"
            : isFirstRightPinnedColumn
                ? "5px 0 5px -5px hsl(var(--border)) inset"
                : undefined,
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        opacity: isPinned ? 0.97 : 1,
        position: isPinned ? "sticky" : "relative",
        background: isPinned ? "hsl(var(--background))" : undefined,
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
    };
}
export const dataTableConfig = {
    comparisonOperators: [
        { label: "Contains", value: "ilike" },
        { label: "Does not contain", value: "notIlike" },
        { label: "Is", value: "eq" },
        { label: "Is not", value: "notEq" },
        { label: "Starts with", value: "startsWith" },
        { label: "Ends with", value: "endsWith" },
        { label: "Is empty", value: "isNull" },
        { label: "Is not empty", value: "isNotNull" },
    ],
    selectableOperators: [
        { label: "Is", value: "eq" },
        { label: "Is not", value: "notEq" },
        { label: "Is empty", value: "isNull" },
        { label: "Is not empty", value: "isNotNull" },
    ],
    logicalOperators: [
        {
            label: "And",
            value: "and",
            description: "All conditions must be met",
        },
        {
            label: "Or",
            value: "or",
            description: "At least one condition must be met",
        },
    ],
    featureFlags: [
        {
            label: "Advanced filter",
            value: "advancedFilter",
            icon: CodeBracketSquareIcon,
            tooltipTitle: "Toggle advanced filter",
            tooltipDescription: "A notion like query builder to filter rows.",
        },
    ],
};
export function exportTableToCSV(
/**
 * The table to export.
 * @type Table<TData>
 */
table, opts = {}) {
    const { filename = "table", excludeColumns = [], onlySelected = false, } = opts;
    // Retrieve headers (column names)
    const headers = table
        .getVisibleLeafColumns()
        .map((column) => column.id)
        .filter((id) => !excludeColumns.includes(id));
    // Build CSV content
    const csvContent = [
        headers.join(","),
        ...(onlySelected
            ? table.getFilteredSelectedRowModel().rows
            : table.getRowModel().rows).map((row) => headers
            .map((header) => {
            const cellValue = row.getValue(header);
            // Handle values that might contain commas or newlines
            return typeof cellValue === "string"
                ? `"${cellValue.replace(/"/g, '""')}"`
                : cellValue;
        })
            .join(",")),
    ].join("\n");
    // Create a Blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    // Create a link and trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
