var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button } from "../components/button";
import { DataTableFilterCombobox } from "./data_table_combo_box";
import { DataTableViewOptions } from "../components/data_table_view_options";
import { DataTableFilterItem } from "./data_table_filter_item";
import { DataTableMultiFilter } from "./data_table_multi_filter";
import { useSearchParams } from "react-router-dom";
import classNames from "../class_names";
import { FunnelIcon, PlusIcon } from "@heroicons/react/24/outline";
/**
 * Top level component which renders each of the differnet filters
 */
export function DataTableAdvancedToolbar(_a) {
    var { table, filterFields = [], asyncFilter, children, className, columnIdMap } = _a, props = __rest(_a, ["table", "filterFields", "asyncFilter", "children", "className", "columnIdMap"]);
    const [searchParams, setSearchParams] = useSearchParams();
    const options = React.useMemo(() => {
        return filterFields.map((field) => {
            var _a;
            return {
                id: crypto.randomUUID(),
                label: field.label,
                value: field.value,
                options: (_a = field.options) !== null && _a !== void 0 ? _a : [],
            };
        });
    }, [filterFields]);
    const initialSelectedOptions = React.useMemo(() => {
        return options
            .filter((option) => searchParams.has(option.value))
            .map((option) => {
            var _a, _b;
            const value = searchParams.get(String(option.value));
            const [filterValue, filterOperator] = (_a = value === null || value === void 0 ? void 0 : value.split("~").filter(Boolean)) !== null && _a !== void 0 ? _a : [];
            return Object.assign(Object.assign({}, option), { filterValues: (_b = filterValue === null || filterValue === void 0 ? void 0 : filterValue.split(".")) !== null && _b !== void 0 ? _b : [], filterOperator });
        });
    }, [options, searchParams]);
    const [selectedOptions, setSelectedOptions] = React.useState(initialSelectedOptions);
    const [openFilterBuilder, setOpenFilterBuilder] = React.useState(initialSelectedOptions.length > 0 || false);
    const [openCombobox, setOpenCombobox] = React.useState(false);
    function onFilterComboboxItemSelect() {
        setOpenFilterBuilder(true);
        setOpenCombobox(true);
    }
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: classNames("flex w-full flex-col space-y-2.5 p-1", className) }, { children: asyncFilter && (_jsx("div", Object.assign({ className: classNames("flex w-full items-center justify-between space-x-2 p-1", className) }, { children: asyncFilter }))) })), _jsxs("div", Object.assign({ className: classNames("flex w-full flex-col space-y-2.5 overflow-auto p-1", className) }, props, { children: [_jsxs("div", Object.assign({ className: "ml-auto flex items-center gap-2" }, { children: [children, (options.length > 0 && selectedOptions.length > 0) ||
                                openFilterBuilder ? (_jsxs(Button, Object.assign({ variant: "outline", size: "sm", onClick: () => setOpenFilterBuilder(!openFilterBuilder), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: [_jsx(FunnelIcon, { className: "mr-2 h-4 w-4 shrink-0", "aria-hidden": "true" }), "Filter"] }))) : (_jsx(DataTableFilterCombobox, { options: options.filter((option) => !selectedOptions.some((selectedOption) => selectedOption.value === option.value)), selectedOptions: selectedOptions, setSelectedOptions: setSelectedOptions, onSelect: onFilterComboboxItemSelect })), _jsx(DataTableViewOptions, { table: table, columnIdMap: columnIdMap })] })), _jsxs("div", Object.assign({ className: classNames("flex items-center gap-2", !openFilterBuilder && "hidden") }, { children: [selectedOptions
                                .filter((option) => !option.isMulti)
                                .map((selectedOption) => (_jsx(DataTableFilterItem, { table: table, selectedOption: selectedOption, selectedOptions: selectedOptions, setSelectedOptions: setSelectedOptions, defaultOpen: openCombobox }, String(selectedOption.value)))), selectedOptions.some((option) => option.isMulti) ? (_jsx(DataTableMultiFilter, { table: table, allOptions: options, options: selectedOptions.filter((option) => option.isMulti), setSelectedOptions: setSelectedOptions, defaultOpen: openCombobox })) : null, options.length > 0 && options.length > selectedOptions.length ? (_jsx(DataTableFilterCombobox, Object.assign({ options: options, selectedOptions: selectedOptions, setSelectedOptions: setSelectedOptions, onSelect: onFilterComboboxItemSelect }, { children: _jsxs(Button, Object.assign({ variant: "outline", size: "sm", role: "combobox", className: "h-7 rounded-full text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6", onClick: () => setOpenCombobox(true) }, { children: [_jsx(PlusIcon, { className: "mr-2 h-4 w-4 opacity-50", "aria-hidden": "true" }), "Add filter"] })) }))) : null] }))] }))] }));
}
