import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { dataTableConfig } from "../table_helpers";
import { ToggleGroup, ToggleGroupItem } from "../components/toggle_group";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
const CallsTableContext = React.createContext({
    featureFlags: [],
    setFeatureFlags: () => { },
});
export function useCallsTable() {
    const context = React.useContext(CallsTableContext);
    if (!context) {
        throw new Error("useCallsTable must be used within a CallsTableContextProps");
    }
    return context;
}
export function CallsTableProvider({ children }) {
    const [featureFlags, setFeatureFlags] = React.useState([]);
    return (_jsxs(CallsTableContext.Provider, Object.assign({ value: {
            featureFlags,
            setFeatureFlags,
        } }, { children: [_jsx("div", Object.assign({ className: "w-full overflow-x-auto" }, { children: _jsx(ToggleGroup, Object.assign({ type: "multiple", variant: "outline", size: "sm", value: featureFlags, onValueChange: (value) => setFeatureFlags(value), className: "w-fit" }, { children: _jsx(TooltipProvider, { children: dataTableConfig.featureFlags.map((flag) => (_jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(ToggleGroupItem, Object.assign({ value: flag.value, className: "whitespace-nowrap px-3 text-xs", asChild: true }, { children: _jsxs(TooltipTrigger, { children: [_jsx(flag.icon, { className: "mr-2 h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }), flag.label] }) })), _jsxs(TooltipContent, Object.assign({ align: "start", side: "bottom", sideOffset: 6, className: "flex max-w-60 flex-col space-y-1.5 border bg-background py-2 font-semibold text-wds-gray-6" }, { children: [_jsx("div", { children: flag.tooltipTitle }), _jsx("div", Object.assign({ className: "text-xs text-muted-foreground" }, { children: flag.tooltipDescription }))] }))] }), flag.value))) }) })) })), children] })));
}
